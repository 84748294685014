import { createStore } from "vuex";
import auth from "./auth";

export default createStore({
	state: {
		shoppingCart: []
	},
	getters: {
		shoppingCart(state) {
			return state.shoppingCart;
		},
		shoppingCartSend(state) {
			return state.shoppingCart.map(item => {
				return {
					product_online_id: item.id,
					empresa_id: item.empresa_id,
					precio: item.precio.toString(),
					cantidad: item.cantidad.toString()
				}
			});
		},
		totalPayment(state) {
			return state.shoppingCart.reduce((a, b) => a + parseFloat(b.precio * b.cantidad), 0);
		},
		totalProducts({shoppingCart}) {
			return shoppingCart.reduce((a, b) => a + parseFloat(b.cantidad) , 0);
		},
		productById: (state) => (id) => {
			return state.shoppingCart.find(item => item.id === id);
		},
		isEmptyCart(state) {
			return state.shoppingCart.length == 0 ? true : false;
		},
		productExists: (state) => (id) => {
			return state.shoppingCart.some(item => item.id === id);
		}
	},
	mutations: {
		addCart(state, item) {
			state.shoppingCart = [item, ...state.shoppingCart];
		},
		updateCart(state, item) {
			const {id, cantidad} = item;
			const index = state.shoppingCart.findIndex(item => item.id === id);
			if( index !== -1 ) {
				state.shoppingCart[index]['cantidad'] = cantidad;
			}
		},
		removeCart(state, index) {
			state.shoppingCart.splice(index, 1);
		},
		emptyCart(state) {
			state.shoppingCart = [];
		}
	},
	actions: {
		addCart(context, item) {
			console.log(item);
			item.precio = !item.promocion ? item.producto.precio : item.promocion.descuento; 
			context.commit("addCart", item);
		},
		updateCart(context, item) {
			context.commit("updateCart", item);
		},
		removeCart({ commit }, index) {
			commit("removeCart", index);
		},
		emptyCart(context) {
			context.commit("emptyCart");
		}
	},
	modules: {
		auth
	},
});
