import axios from "axios";

export default {
	namespaced: true,
	state: {
		token: null,
		user: null
	},
	getters: {
		authenticated (state) {
			return state.token && state.user ? true : false;
		},
		user (state) {
			if(! state.user ) {
				return {
					email: null
				}
			}
			return state.user;
		}
	},
	mutations: {
		setToken (state, token) {
			state.token = token;
		},
		setUser (state, user) {
			state.user = user;
		}
	},
	actions: {
		async register ({ dispatch }, model) {
			let response = await axios.post(process.env.VUE_APP_GOU_AFILIADO_URL + "api/v2/auth/register", model)
			console.log('register store success', response.data);
			return dispatch("attempt", response.data.access_token);
		},
		async signIn ({ dispatch }, credentials) {
			let response = await axios.post(process.env.VUE_APP_GOU_AFILIADO_URL + "api/v2/auth/login", credentials)
			return dispatch("attempt", response.data.access_token);
		},
		async attempt ({ commit, state }, token) {
			if( token ) {
				commit('setToken', token);
			}

			if( !state.token ) {
				return;
			}

			try {
				let response = await axios.post(process.env.VUE_APP_GOU_AFILIADO_URL + "api/v2/auth/me");
				console.log('responseMe', response);
				commit('setUser', response.data);
			} catch (error) {
				console.log('error authMe', error);
				commit('setToken', null);
				commit('setuser', null);
			}

		},
		async socialLoginCallback({ dispatch }, parameters) {
			let response = await axios.get(`${ process.env.VUE_APP_GOU_AFILIADO_URL }api/v2/auth/${ parameters.social }/callback`, {
				params: {
					code: parameters.code
				}
			});
			return dispatch('attempt', response.data.access_token);
        },
		signOut ({ commit }) {
			return axios.post(process.env.VUE_APP_GOU_AFILIADO_URL + "api/v2/auth/logout").then(() => {
				commit('setToken', null);
				commit('setUser', null);
			})
		}
	},
};
