<template>
  <footer class="footer px-4">
    <div class="row align-items-center justify-content-lg-between">
      <div class="col-lg-6">
        <div class="copyright text-center text-lg-left text-muted">
          © {{ year }}
          <a
            href="https://dechali.com"
            class="font-weight-bold ml-1"
            target="_blank"
            >DeChaLi</a
          >
        </div>
      </div>
      <div class="col-lg-6">
        <ul
          class="nav nav-footer justify-content-center justify-content-lg-center"
        >
          <li class="nav-item">
            <a
              href="https://dechali.com/servicios"
              class="nav-link"
              target="_blank"
              >Servicios</a
            >
          </li>
          <li class="nav-item">
            <a
              href="https://dechali.com/nosotros"
              class="nav-link"
              target="_blank"
              >Acerca de nosotros</a
            >
          </li>
          <li class="nav-item">
            <a
              href="https://dechali.com/contacto"
              class="nav-link"
              target="_blank"
              >Contáctanos</a
            >
          </li>
          <li class="nav-item">
            <a
              href="https://dechali.com/blog"
              class="nav-link"
              target="_blank"
              >Blog</a
            >
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
<style>
</style>
