<template>
    <div class="h-100">
        <h5 class="text-muted text-uppercase" v-show="title">{{ title }}</h5>
        <table class="table table-sm">
            <thead>
                <th>PRODUCTO</th>
                <th class="text-right">SUBTOTAL</th>
            </thead>
            <tbody>
                <tr v-show="isEmptyCart">
                    <th colspan="2" class="p-5 bg-light text-center text-muted text-uppercase">
                        Vacio
                    </th>
                </tr>
                <tr v-for="(item, index) in shoppingCart" :key="index">
                    <td><b>{{ item.cantidad }} x </b> {{ item.producto.nombre }}</td>
                    <td class="text-right">Bs {{ numberFormat( item.precio * item.cantidad ) }}</td>
                </tr>
            </tbody>
            <tfoot>
                <tr class="text-right">
                    <th><span class="h4"> TOTAL</span></th>
                    <th><span class="h4"> Bs {{ numberFormat( totalPayment ) }}</span></th>
                </tr>
            </tfoot>
        </table>
    </div>
</template>
<script>
    import { mapGetters } from "vuex";
    import helpers from "../plugins/helpers";

    export default {
        name: "cart-summary",
        props: ['title'],
        computed: {
            ...mapGetters({
				shoppingCart: "shoppingCart",
				totalProducts: "totalProducts",
				totalPayment: "totalPayment",
                isEmptyCart: "isEmptyCart"
            }),
        },
        methods: {
            numberFormat (value) {
                return helpers.numberFormat(value);
            }
        }
    };
</script>
<style scoped>

</style>
