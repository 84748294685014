<template>
    <div class="row h-100">
        <div class="col-lg-6">
            <img class="img-fluid" :src="formatProductImage(item.producto.imagen)" alt="Product image">
        </div>
        <div class="col-lg-6">
            <h6 class="text-primary text-uppercase" v-show="existPromotion">{{ promocion.tipo_promocion }}</h6>
            <h3 class="text-uppercase">{{ item.producto.nombre }}</h3>
            <p>{{ item.producto.descripcion }}</p>
            <div class="text-primary h4" v-if="existPromotion">
                <del class="text-muted">Bs {{ numberFormat( promocion.precio_normal ) }}</del><br/>
                Bs {{ numberFormat( promocion.descuento ) }}
            </div>
            <div class="text-primary h4" v-else>
                Bs {{ numberFormat( item.producto.precio ) }}
            </div>
            <div class="row">
                <div class="col-lg-6" v-show="itemExists">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <button class="btn btn-lg btn-light shadow-none" type="button">
                                <i class="ni ni-fat-delete"></i>
                            </button>
                        </div>
                        <div class="form-control form-control-lg bg-light border-0 text-center">
                            {{ item.cantidad }}
                        </div>
                        <div class="input-group-append">
                            <button class="btn btn-lg btn-light shadow-none" type="button" @click="addCart(item)">
                                <i class="ni ni-fat-add"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <button v-if="itemExists" class="btn btn-lg btn-danger" @click="removeCart(item)">
                        <i class="mdi mdi-cart-minus"></i> Quitar del carrito
                    </button>
                    <button v-else class="btn btn-lg btn-primary" @click="addCart(item)">
                        <i class="mdi mdi-cart-plus"></i> Agregar al carrito
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import helpers from '../plugins/helpers';

    export default {
        name: "detail-product",
        props: {
            item: {
                type: Object,
                description: "product",
            },
        },
        computed: {
            promocion() {
                return this.item.promocion ? JSON.parse(this.item.promocion) : {};
            },
            existPromotion() {
                return this.item.promocion ? true : false;
            },
            itemExists() {
                return this.$store.getters.productExists(this.item.id);
            },
        },
        methods: {
            formatProductImage: function (value) {
                if (!value) return "defaultSrc";
                return process.env.VUE_APP_GOU_AFILIADO_URL + value.split(",")[0];
            },
            numberFormat ( value ) {
                return helpers.numberFormat(value, 2);
            },
            addCart(item) {
                if(this.$store.getters.productExists(item.id)) {
                    const cartItem = this.$store.getters.productById(item.id);
                    this.$store.dispatch("updateCart", {id: item.id, cantidad: cartItem.cantidad + 1});
                } else {
                    item.cantidad = 1;
                    this.$store.dispatch("addCart", item);
                }
                this.$notify({
                    group: "app",
                    type: "success",
                    text: "Agregado al carrito",
                    position: "center",
                    // ignoreDuplicates: true
                });
            },
            removeCart(item) {
                const index = this.$store.getters.shoppingCart.findIndex(param => param.id === item.id);
                this.$store.dispatch("removeCart", index);
            },
        }
    };
</script>
<style scoped>

</style>
