<template>
    <div class="main-content">
        <!-- Navbar -->
        <base-nav
            class="navbar-top navbar-horizontal navbar-dark"
            containerClasses="px-4 container"
            expand
        >
            <template v-slot="{ closeMenu }">
                <!-- Collapse header -->
                <div class="navbar-collapse-header d-md-none">
                    <div class="row">
                        <div class="col-6 collapse-brand">
                            <router-link to="/">
                                <img src="img/brand/icon.png" />
                            </router-link>
                        </div>
                        <div class="col-6 collapse-close">
                            <button
                                type="button"
                                @click="closeMenu"
                                class="navbar-toggler"
                                aria-label="Toggle sidenav"
                            >
                                <span></span>
                                <span></span>
                            </button>
                        </div>
                    </div>
                </div>
                <!-- Navbar items -->
                <ul class="navbar-nav ml-auto">
                    <li class="nav-item">
                        <router-link class="nav-link nav-link-icon text-primary d-flex align-items-center" to="/">
                            <i class="mdi mdi-24px mdi-home-outline"></i>
                            <span class="nav-link-inner--text">Home</span>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link nav-link-icon text-primary d-flex align-items-center" to="/register">
                            <i class="mdi mdi-24px mdi-account-plus-outline"></i>
                            <span class="nav-link-inner--text">Registrarme</span>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link nav-link-icon text-primary d-flex align-items-center" to="/login">
                            <i class="mdi mdi-24px mdi-account-outline"></i>
                            <span class="nav-link-inner--text">Ingresar</span>
                        </router-link>
                    </li>
                </ul>
            </template>
        </base-nav>
        <!-- Header -->
        <div class="header py-7 py-lg-8">
            <div class="container">
                <div class="header-body text-center mb-3">
                <div class="row justify-content-center">
                    <div class="col-lg-5 col-md-6">
                    <nav aria-label="breadcrumb">
                            <ol class="breadcrumb bg-white small">
                                <li class="breadcrumb-item">
                                    <router-link to="/">
                                        Home
                                    </router-link>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page">{{ $route.name }}</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                </div>
            </div>
        </div>
        <!-- Page content -->
        <div class="container mt--8 pb-5">
            <router-view></router-view>
        </div>
        <footer class="py-5">
            <div class="container">
                <div class="row align-items-center justify-content-xl-between">
                    <div class="col-xl-6">
                        <div class="copyright text-center text-xl-left text-muted">
                        &copy; {{ year }}
                        <a
                            href="https://dechali.com"
                            class="font-weight-bold ml-1"
                            target="_blank"
                            >DeChaLi</a
                        >
                        </div>
                    </div>
                    <div class="col-xl-6">
                        <ul
                        class="nav nav-footer justify-content-center justify-content-xl-end"
                        >
                        <li class="nav-item">
                            <a
                            href="https://dechali.com/servicios"
                            class="nav-link"
                            target="_blank"
                            >Servicios</a
                            >
                        </li>
                        <li class="nav-item">
                            <a
                            href="https://dechali.com/nosotros"
                            class="nav-link"
                            target="_blank"
                            >Acerca de nosotros</a
                            >
                        </li>
                        <li class="nav-item">
                            <a
                            href="https://dechali.com/contacto"
                            class="nav-link"
                            target="_blank"
                            >Contáctanos</a
                            >
                        </li>
                        <li class="nav-item">
                            <a
                            href="https://dechali.com/blog"
                            class="nav-link"
                            target="_blank"
                            >Blog</a
                            >
                        </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>
<script>
    export default {
        name: "auth-layout",
        data() {
            return {
            year: new Date().getFullYear(),
            showMenu: false,
            };
        },
    };
</script>
<style></style>
