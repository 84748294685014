<template>
    <div>
        <loading :active="isLoading" 
			color="#FF4200"
			:can-cancel="true" 
			:is-full-page="true"
		></loading>
        <div class="container pt-5 pb-5">
            <detail-order v-if="pedido.data" :order="pedido.data">
                <template v-slot:actions>
                    <router-link to="/pedidos" class="mt-3 btn btn-primary">
                        Ir a mis pedidos
                    </router-link>
                    <router-link to="/" class="mt-3 btn btn-primary">
                        Seguir comprando
                    </router-link>
                </template>
            </detail-order>
        </div>
    </div>
</template>

<script>
    import axios from "axios";
    import DetailOrder from "../components/DetailOrder.vue";
    import Loading from 'vue3-loading-overlay';
    import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';

    export default {
        name: "pedido",
        components: {
            DetailOrder,
            Loading,
        },
        data() {
            return {
                loading: {
                    order: true
                },
                pedido: '',
            };
        },
        computed: {
			pedidoId() {
                return this.$route.params.pedidoId;
            },
            isLoading() {
                return this.loading.order ? true : false;
            }
		},
        methods: {
            getPedido(params) {
                return axios.get(process.env.VUE_APP_GOU_AFILIADO_URL + "api/v2/pedidos/" + this.pedidoId, {
                    params
                });
            },
        },
        created () {
            let vm = this;
            Promise.all([vm.getPedido({estado: 'activo'})])
            .then(function (results) {
                console.log(results);
                const pedidoResponse = results[0];
                vm.pedido = pedidoResponse.data;
                vm.loading.order = false;
            })
            .catch(function (error) {
                console.error(error);
                vm.loading.order = false;
            });
        }
    };
</script>

<style scoped>
</style>
