<template>
    <div>
        <base-table :data="shoppingCart">
            <template v-slot:columns>
                <th width="35%">Producto</th>
                <th width="20%">Precio</th>
                <th width="15%">Cantidad</th>
                <th width="20%">Subtotal</th>
                <th width="10%"></th>
            </template>
            <template v-slot:default="row">
                <th scope="row" class="text-left">
                    {{ row.item.producto.nombre }}<br/>
                    <small class="text-muted">{{ row.item.tienda.nombre }}</small>
                </th>
                <td class="text-right">
                    {{ numberFormat( row.item.precio ) }}
                </td>
                <td>
                    <input type="number" class="form-control text-center" v-model="row.item.cantidad">
                </td>
                <td class="text-right">
                    {{ numberFormat( row.item.precio * row.item.cantidad ) }}
                </td>
                <td>
                    <a href="javascript:void(0)" class="text-danger" title="Quitar" @click="removeCart(row.item)">
                        <i class="mdi mdi-18px mdi-delete"></i>
                    </a>
                </td>
            </template>
            <template v-slot:footer>
                <tr class="text-right">
                    <th colspan="3">TOTAL</th>
                    <th>{{ numberFormat( totalPayment ) }}</th>
                </tr>
            </template>
        </base-table>
    </div>
</template>
<script>
    import { mapGetters, mapActions } from "vuex";
    import helpers from "../plugins/helpers";

    export default {
        name: "cart-table",
        props: {
            
        },
        computed: {
            ...mapGetters({
				shoppingCart: "shoppingCart",
				totalProducts: "totalProducts",
				totalPayment: "totalPayment",
                isEmptyCart: "isEmptyCart"
            }),
        },
        methods: {
            ...mapActions({
                removeCartAction: "removeCart"
            }),
            removeCart(item) {
                const index = this.shoppingCart.findIndex(element => element.id = item.id);
                console.log(index);
                this.removeCartAction(index);
            },
            numberFormat(value) {
                return helpers.numberFormat(value, 2);
            }
        }
    };
</script>
<style scoped>

</style>
