<template>
    <div class="wrapper-stepper">
        <div class="stepper">
            <div class="stepper-progress">
                <div class="stepper-progress-bar" :style="'width:' + stepperProgress">

                </div>
            </div>
            <div class="stepper-item" :class="{ 'current': step == (index + 1), 'success': step > (index + 1) }" v-for="(item, index) in steps" :key="index">
                <div class="stepper-item-counter">
                    <!-- <img class="icon-success" src="./icon.svg" alt=""> -->
                    <i class="icon-success mdi mdi-24px mdi-check"></i>
                    <span class="number">
                        {{ index + 1 }}
                    </span>
                </div>
                <div class="stepper-item-title d-none d-md-block">
                    {{ item }}
                </div>
            </div>
        </div>
        <div class="stepper-content" v-for="(item, index) in steps" :key="index">
            <div class="stepper-panel" v-if="step == (index + 1)">
                <slot name="item" v-bind="{item, index}"/>
            </div>
        </div>
        <div class="controls">
            <button class="btn btn-light float-left" @click="step--" :hidden="step == 1">
                Anterior
            </button>
            <button class="btn btn-primary float-right" @click="step++" :hidden="step == this.numberSteps">
                Siguiente
            </button>
        </div>
    </div>
</template>
<script>
    import { mapGetters } from "vuex";

    export default {
        name: "stepper",
        props: {
            steps: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                step: 1
            }
        },
        computed: {
            ...mapGetters({
                authenticated: "auth/authenticated",
            }),
            numberSteps () {
                return this.steps.length;
            },
            stepperProgress () {
                return ( 100 / ( this.numberSteps - 1 ) ) * ( this.step -1 ) + '%'
            },

        },
        watch: {
            step( newValue ) {
                if(newValue > 1 && !this.authenticated ) {
                    this.$notify({
						group: "app",
						type: "info",
						text: "Inicia sesión para continuar",
					});
                    this.step--;
                }
            }
        }
    };
</script>
<style lang="scss">
    @import "../assets/scss/stepper.scss";
</style>