<template>
  <div class="row justify-content-center">
    <div class="col-lg-12">NOT FOUND</div>
  </div>
</template>
<script>
export default {
  name: "not-found",
  data() {
    return {
      model: {
        email: "",
        password: "",
      },
    };
  },
};
</script>
<style></style>
