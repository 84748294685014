<template>
	<div class="product-grid shadow-sm">
		<div class="product-image">
			<a href="javascript:void(0)" @click="$emit('detailItem', item)" class="image">
				<figure>
					<img class="pic-1" :src="formatProductImage(item.producto.imagen)"/>
				</figure>
			</a>
			<span class="product-sale-label" v-show="existPromotion">{{ promocion.tipo_promocion }}</span>
			<ul class="product-links">
				<li><a href="javascript:void(0)" @click="$emit('showItem', item)" class="text-decoration-none"><i class="mdi mdi-18px mdi-eye"></i> Vista rápida</a></li>
				<li><a href="javascript:void(0)" @click="$emit('addCart', item)" class="text-decoration-none"><i class="mdi mdi-18px mdi-cart-plus"></i> Agregar al carrito</a></li>
			</ul>
		</div>
		<div class="product-content">
			<h3 class="title">
			{{ item.producto.nombre }}
			</h3>
			<div class="price" v-if="existPromotion">
			<span>Bs {{ numberFormat( promocion.precio_normal ) }}</span>
			Bs {{ numberFormat( promocion.descuento ) }}
			</div>
			<div class="price" v-else>
			Bs {{ numberFormat( item.producto.precio ) }}
			</div>
			<small class="text-muted" data-toggle="tooltip">{{ item.tienda.nombre }}</small>
		</div>
	</div>
</template>
<script>
	import helpers from "../plugins/helpers"
	export default {
	name: "card-product",
	props: {
		item: {
		type: Object,
		description: "product",
		},
	},
	computed: {
		promocion() {
		return this.item.promocion ? this.item.promocion : {};
		},
		existPromotion() {
		return this.item.promocion ? true : false;
		}
	},
	methods: {
		formatProductImage: function (value) {
			if (!value) return "defaultSrc";
			return process.env.VUE_APP_GOU_AFILIADO_URL + value.split(",")[0];
		},
		numberFormat (value) {
			console.log(value);
			return helpers.numberFormat(value, 2);
		}
	}
	};
</script>
<style scoped>
  /* card */
	.product-grid{
		font-family: 'Poppins', sans-serif;
		text-align: center;
		background-color: #FFFFFF;
	}

	.product-grid:hover {
		box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
	}
	.product-grid .product-image{ position: relative; }
	.product-grid .product-image a.image{ display: block; }
	.product-grid .product-image img{
		width: 100%;
		height: 30vh;
		object-fit:cover;
	}

	/* Circle */
	.product-grid .product-image figure {
		margin: 0px;
		position: relative;
	}
	.product-grid .product-image figure::before {
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 2;
		display: block;
		content: '';
		width: 0;
		height: 0;
		background: rgba(255,255,255,.2);
		border-radius: 100%;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		opacity: 0;
	}
	.product-grid .product-image figure:hover::before {
		-webkit-animation: circle .75s;
		animation: circle .75s;
	}
	@-webkit-keyframes circle {
		0% {
			opacity: 1;
		}
		40% {
			opacity: 1;
		}
		100% {
			width: 130%;
			height: 130%;
			opacity: 0;
		}
	}
	@keyframes circle {
		0% {
			opacity: 1;
		}
		40% {
			opacity: 1;
		}
		100% {
			width: 130%;
			height: 130%;
			opacity: 0;
		}
	}
	/* end */
	.product-grid .product-sale-label{
		color: #fff;
		background: #FF9775;
		font-size: 13px;
		text-transform: uppercase;
		letter-spacing: 1px;
		padding: 2px 8px;
		position: absolute;
		top: 15px;
		left: 15px;
		/* animation: bg-animate 5s infinite linear; */
	}
	.product-grid .product-links{
	margin: 0px;
	padding: 0px;
		list-style: none;
		opacity: 0;
		overflow: hidden;
		position: absolute;
		bottom: 15px;
		left: 20px;
		transition: all 0.3s ease 0s;
	}
	.product-grid:hover .product-links{ opacity: 1; }
	.product-grid .product-links li{
		margin: 0 0 5px;
		opacity: 0;
		transform: translateX(-100%);
		transition: all 0.3s ease 0s;
	}
	.product-grid:hover .product-links li:nth-child(2){ transition: all 0.3s ease 0.15s; }
	.product-grid:hover .product-links li{
		opacity: 1;
		transform: translateX(0);
	}
	.product-grid .product-links li a{
		border: .1rem solid #ebebeb;
		color: #333;
		background-color: #FFF;
		font-size: 14px;
		padding: 8px 10px;
		display: block;
	}
	.product-grid .product-links li a:hover{
		color: #fff;
		border-color: #FF9775;
		background-color: #FF9775;
	}
	.product-grid .product-links li a i{ margin: 0 5px 0 0; }
	.product-grid .product-content{ padding: 1em 0 1em; }
	.product-grid .title{
		font-size: 14px;
		font-weight: 500;
		text-transform: capitalize;
		margin: 0 0 8px;
	}
	.product-grid .title a{
		color: #000;
		transition: all 0.3s ease 0s;
	}
	.product-grid .price{
		color: #FF9775;
		font-size: 16px;
		font-weight: 500;
	}
	.product-grid .price span{
		color: #d4d3d3;
		font-size: 14px;
		font-weight: 400;
		text-decoration: line-through;
		margin: 0 5px 0 0;
	}
	
	@media screen and (max-width:1200px){
		.product-grid{ margin: 0 0 30px; }
	}
</style>
