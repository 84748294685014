<template>
	<div class="row pt-4">
		<loading :active="isLoading" 
			color="#FF4200"
			:can-cancel="true" 
			:is-full-page="true"
		></loading>
		<div class="col-lg-2">
		<div class="card h-100 border-top-0 rounded-0 d-none d-md-block">
			<div class="card-body">
			<div class="list-group">
				<li class="list-group-item p-2 border-0" data-toggle="tooltip" title="asfafd">
				<div class="d-flex justify-content-between">
					<b>CATEGORIAS</b>
				</div>
				</li>
				<a 
					href="javascript:void(0)" 
					class="list-group-item p-2 list-group-item-action border-0"
					:class="{ active: indexActiveCategory === index }"
					v-for="(category, index) in listCategories" 
					:key="index"
					@click="indexActiveCategory = index"
				>
					<img :src="category.imagen" alt="icon" width="20"> {{ category.nombre }}
				</a>
			</div>
			</div>
		</div>
		</div>
		<div class="col-lg-10 pl-0">
			<div class="row">
				<div class="col-lg-12">
					<Carousel :autoplay="10000" :wrap-around="true">
						<Slide v-for="(item, index) in listBanners" :key="index">
							<div class="carousel__item p-0">
								<div class="card text-white bg-dark m-0 p-0 border-0 rounded-0 w-100" style="max-height: 60vh !important;">
								<img class="card-img" :src="item.imagen" alt="Banner">
								<div class="card-img-overlay">
									<h1 class="card-title text-white">{{ item.titulo }}</h1>
									<p class="card-text">{{ item.descripcion }}</p>
								</div>
								</div>
							</div>
						</Slide>
						<template #addons>
							<Pagination />
						</template>
					</Carousel>
				</div>
			</div>
			<div class="container">
				<div class="row">
					<div class="col-lg-12">
						<div class="row">
							<div class="col-lg-12">
								<p class="text-center h2 text-dark">¿Qué quieres comprar hoy?</p>
								<carousel :settings="settings" :breakpoints="breakpoints">
									<slide v-for="(item, index) in listCategories" :key="index">
										<div
											class="carousel_category"
											:class="{ active: indexActiveCategory === index }"
											@click="indexActiveCategory = index"
										>
											<div class="icon">
												<img :src="item.imagen" alt="icon">
											</div>
											<h6 class="text-uppercase">{{ item.nombre }}</h6>
										</div>
									</slide>
									<template #addons>
										<Navigation />
									</template>
								</carousel>
							</div>
						</div>
						<div class="row">
							<div class="col-lg-12" v-show="existActiveCategory">
								<p>Lo mas vendido esta semana en <b class="text-primary">{{ activeCategory.nombre }}</b></p>
							</div>
							<div class="col-lg-12">
								<i class="fa fa-border-all"></i> Mostrando {{ pagination.from }} - {{ pagination.to }} de {{ pagination.total }} resultados
							</div>
						</div>
						<hr class="mt-2 mb-3">
						<div class="row" v-if="existProducts">
							<div
								class="col-lg-3 col-md-4 mb-4"
								v-for="(item, index) in listProducts"
								:key="index"
							>
								<card-product :item="item" @add-cart="addCart" @show-item="showItem" />
							</div>
							<div class="col-lg-12">
								<base-pagination :perPage="pagination.perPage" :total="pagination.total" :value="pagination.currentPage" align="center" @input="paginate"></base-pagination>
							</div>
						</div>
						<div class="row" v-else>
							<div class="col-lg-12">
								<p class="p-5 bg-light text-muted text-center">Ningun resultado encontrado</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<modal v-model:show="modals.modal1" modal-classes="modal-dialog-centered">
			<template v-slot:header>
				<h5 class="modal-title">Vista rapida</h5>
			</template>
			<div class="row">
				<div class="col-lg-5 d-flex align-items-center">
					<img class="img-fluid" :src="formatProductImage(getItemActive.producto.imagen)">
				</div>
				<div class="col-lg-7">
					<h6 class="text-primary text-uppercase" v-show="existPromotionItemActive">{{ promotionItemActive.tipo_promocion }}</h6>
					<h4>{{ getItemActive.producto.nombre }}</h4>
					<div class="text-primary h4" v-if="existPromotionItemActive">
						<del class="text-muted">Bs {{ numberFormat(promotionItemActive.precio_normal) }}</del><br/>
						Bs {{ numberFormat(promotionItemActive.descuento) }}
					</div>
					<div class="text-primary h4" v-else>
						Bs {{ numberFormat(getItemActive.producto.precio) }}
					</div>
					<p>{{ getItemActive.producto.descripcion }}</p>
					<div class="w-100">
						<small class="text-muted text-uppercase"><b>Categoría: </b> {{ getItemActive.producto.categoria.nombre }}</small><br/>
						<small class="text-muted text-uppercase"><b>Tienda: </b> {{ getItemActive.tienda.nombre }}</small>
					</div>
					<div class="input-group" v-show="itemExists">
						<div class="input-group-prepend">
							<button class="btn btn-light shadow-none" type="button">
								<i class="fa fa-minus"></i>
							</button>
						</div>
						<div class="form-control text-center bg-light border-0">
							{{ getItemActive.cantidad }}
						</div>
						<div class="input-group-append">
							<button class="btn btn-light shadow-none" type="button" @click="addCart(getItemActive)">
								<i class="fa fa-plus"></i>
							</button>
						</div>
					</div>
				</div>
			</div>              
			<template v-slot:footer>
				<button v-if="itemExists" class="btn btn-danger" @click="removeCart(getItemActive)">
					<i class="mdi mdi-18px mdi-delete"></i> Quitar del carrito
				</button>
				<button v-else class="btn btn-primary" @click="addCart(getItemActive)">
					<i class="mdi mdi-18px mdi-cart-plus"></i> Agregar al carrito
				</button>
				<base-button type="light" class="ml-auto" @click="modals.modal1 = false">
				<i class="mdi mdi-18px mdi-close"></i> Cerrar
				</base-button>
			</template>
		</modal>
	</div>
</template>
<script>
	import axios from "axios";
	import "vue3-carousel/dist/carousel.css";
	import { Carousel, Slide, Navigation, Pagination } from "vue3-carousel";
	// Import component
    import Loading from 'vue3-loading-overlay';
    // Import stylesheet
    import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';

	import helpers from "../plugins/helpers";

	export default {
		components: {
			Carousel,
			Slide,
			Navigation,
			Pagination,
			Loading
		},
		props: ["searchText", "searchBy"],
		data() {
			return {
				categories: {},
				banners: {},
				loading: {
					categories: true,
					products: true,
					banners: true
				},
				products: {},
				settings: {
					itemsToShow: 1,
					snapAlign: "center",
				},
				// breakpoints are mobile first
				// any settings not specified will fallback to the carousel settings
				breakpoints: {
					700: {
					itemsToShow: 3.5,
					snapAlign: "center",
					},
					1024: {
					itemsToShow: 4,
					snapAlign: "start",
					},
				},
				indexActiveCategory: -1,
				modals: {
					modal1: false
				},
				itemActive: {},
				pagination: {
					currentPage: 1,
					from: 0,
					to: 0,
					perPage: 16,
					total: 0,
				}
			};
		},
		computed: {
			isLoading () {
				return this.loading.categories || this.loading.products || this.loading.banners ? true : false;
			},
			listCategories() {
			return this.categories.data;
			},
			listProducts() {
				if( !this.products.data ) return [];
				return this.products.data.map( item => {
					item.promocion = JSON.parse(item.promocion) 
					return item;
				});
			},
			listBanners() {
				return this.banners.data;
			},
			existProducts() {
				return this.listProducts.length > 0 ? true : false;
			},
			totalItems() {
			return this.products.length;
			},
			isEmptyItemActive () {
				if( this.itemActive && Object.keys(this.itemActive).length === 0 && Object.getPrototypeOf(this.itemActive) === Object.prototype ) {
					return true;
				}
				return false;
			},
			getItemActive() {
				if( this.isEmptyItemActive ) return {
					producto: {
						nombre: null,
						imagen: null,
						categoria: {
							nombre: ''
						}
					},
					tienda: {
						nombre: ''
					}
				}
				return this.itemActive;
			},
			promotionItemActive() {
				console.log('promotionItemActive', this.itemActive);
                return !this.isEmptyItemActive && this.itemActive.promocion ? this.itemActive.promocion : {};
            },
            existPromotionItemActive() {
                return !this.isEmptyItemActive && this.itemActive.promocion ? true : false;
            },
			itemExists() {
			return this.$store.getters.productExists(this.itemActive.id);
			},
			activeCategory() {
			if( this.indexActiveCategory == -1 ) {
				return {};
			}
			return this.categories.data[this.indexActiveCategory];
			},
			existActiveCategory() {
			return this.indexActiveCategory == -1 ? false : true;
			}
		},
		watch: {
			searchText: function(newValue) {
				let params = {};
				if(newValue != null && typeof newValue === "string" && newValue.length > 0) {
					params = {
						searchBy: this.searchBy,
						searchText: newValue
					}
				}
				this.getOnlineProducts(params)
			},
			indexActiveCategory: function(newValue) {
			let params = {};
			if(newValue != -1) {
				params = {
				categories: this.activeCategory.id  
				};
				this.getOnlineProducts(params);
			}
			}
		},
		methods: {
			getBanners() {
				let vm = this;
				axios
				.get(process.env.VUE_APP_GOU_AFILIADO_URL + "api/v2/banners", {
					params: {
						estado: 'activo'
					},
				})
				.then(function (response) {
					console.log("getBanners", response);
					vm.banners = response.data;
					vm.loading.banners = false;
				})
				.catch(function (error) {
					console.error(error);
				});
			},
			getCategories() {
				let vm = this;
				axios
				.get(process.env.VUE_APP_GOU_AFILIADO_URL + "api/v2/categorias", {
					params: {
						estado: 'activo'
					},
				})
				.then(function (response) {
					console.log("getCategories", response);
					vm.categories = response.data;
					vm.loading.categories = false;
				})
				.catch(function (error) {
					console.error(error);
					vm.isLoading = false;
					alert('Error');
				});
			},
			getOnlineProducts(params = null) {
				console.log('getOnlineProducts', params)
				let vm = this;
				axios
				.get(process.env.VUE_APP_GOU_AFILIADO_URL + "api/v2/productOnlinePost", {
					params
				})
				.then(function (response) {
					console.log("getProductsOnline", response);
					vm.products = response.data;
					vm.pagination.currentPage = response.data.meta.current_page;
					vm.pagination.from = response.data.meta.from;
					vm.pagination.to = response.data.meta.to;
					vm.pagination.perPage = response.data.meta.per_page;
					vm.pagination.total = response.data.meta.total;
					vm.loading.products = false;
				})
				.catch(function (error) {
					console.log(error);
				});
			},
			formatProductImage: function (value) {
			if (!value) return "defaultSrc";
			return process.env.VUE_APP_GOU_AFILIADO_URL + value.split(",")[0];
			},
			addCart(item) {
			if(this.$store.getters.productExists(item.id)) {
				const cartItem = this.$store.getters.productById(item.id);
				this.$store.dispatch("updateCart", {id: item.id, cantidad: cartItem.cantidad + 1});
			} else {
				item.cantidad = 1;
				this.$store.dispatch("addCart", item);
			}
			this.$notify({
				group: "app",
				type: "success",
				text: "Agregado al carrito",
				position: "top",
				ignoreDuplicates: true
			});
			},
			removeCart(item) {
			const index = this.$store.getters.shoppingCart.findIndex(param => param.id === item.id);
			this.$store.dispatch("removeCart", index);
			},
			showItem(item) {
				if( this.$store.getters.productExists(item.id) ) {
					this.itemActive = this.$store.getters.productById(item.id);
				} else {
					this.itemActive = item;
				}
				this.modals.modal1 = true;
			},
			numberFormat(value) {
				return helpers.numberFormat(value, 2);
			},
			paginate(page) {
				this.getOnlineProducts({page});
			},
		},
		mounted() {
			this.getBanners();
			this.getCategories();
			this.getOnlineProducts();
			console.log('gou afiliado url', process.env.VUE_APP_GOU_AFILIADO_URL);
			// this.isLoading = false;
		},
	};
</script>
<style>
	/* carousel */
	.carousel__item {
		width: 100%;
		background-color: #ffebee;
		color: var(--vc-clr-white);
		border-radius: 4px;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 5px;
	}

	.carousel__item > a {
	color: #616161;
	}

	.carousel__item.active > a {
	color: white;
	}

	.carousel__slide {
	padding: 10px;
	}

	.carousel__prev,
	.carousel__next {
		box-sizing: content-box;
		border: 5px solid white !important;
		color: #ff4000b9 !important;
		background-color: transparent;
		font-size: xx-large;
	}

	.carousel__prev--in-active,
	.carousel__next--in-active {
		display: none !important;
	}

	a.active {
	color: red;
	}
	/* end */

	/* carousel category */
	.carousel_category {
		display: flex;
		width: 100%;
		-moz-box-pack: center;
		justify-content: center;
		-moz-box-align: center;
		align-items: center;
		flex-direction: column;
		position: relative;
		border: 1px solid transparent;
		cursor: pointer;
		font-size: 14px;
		letter-spacing: 0.24em;
		text-transform: uppercase;
		text-align: center;
	}
	.carousel_category.active > .icon{
		background-color: #ff4000b9;
	}
	.carousel_category.active > .icon > img {
		filter: brightness(0) invert(1);
	}
	.carousel_category .icon {
		border: 1px solid #FF6E41;
		border-radius: 50%;
		width: 60px;
		height: 60px;
		display: flex;
		-moz-box-pack: center;
		justify-content: center;
		-moz-box-align: center;
		align-items: center;
	}
	.carousel_category .icon > img {
		width: 25px !important;
	}
	.carousel_category > h6 {
		margin-top: 14px;
		font-size: 11px;
		overflow-wrap: normal;
	}
	/* end */
	/* list category */
	.list-group-item.active {
		background-color: #f8f9fa !important;
		color: #FF6E41 !important;
	}
	/* end */
</style>
