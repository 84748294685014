<template>
    <div class="pt-4">
        <div class="row">
            <div class="col-lg-12">
                <div class="img-bg">
                    <div class="transbox">
                        <div class="img-text-top-left">
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb bg-white small">
                                    <li class="breadcrumb-item">
                                        <router-link to="/">
                                            Home
                                        </router-link>
                                    </li>
                                    <li class="breadcrumb-item active" aria-current="page">{{ $route.name }}</li>
                                </ol>
                            </nav>
                        </div>
                        <div class="img-text-centered">
                            <h1 class="text-white">El lugar donde encuentras lo que estás buscando</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row p-5">
                <div class="col-lg-6">
                    <div class="container">
                        <img class="img-fluid h-50" src="../../public/img/about/1.png" alt="" lazy="loading">
                    </div>
                </div>
                <div class="col-lg-6 d-flex align-items-center">
                    <div class="container">
                        <h5 class="text-primary w-100">Queremos que comprar en Internet sea tan fácil como ir a la tienda</h5>
                        <p class="text-muted">
                            Estamos seguros que cualquier persona que tiene un celular o una computadora, puede comprar lo que necesite desde un solo lugar.
                            Gou Tienda te conecta con tus empresas favoritas para que puedas adquirir productos de forma rápida, segura y fácil.
                        </p>
                    </div>
                </div>
            </div>
            <div class="row p-5">
                <div class="col-lg-6 d-flex align-items-center">
                    <div class="container">
                        <h5 class="text-primary">Operamos en muchas ciudades de Bolivia</h5>
                        <p class="text-muted">
                            Tenemos presencia en el eje troncal del país, con muchos comercios afiliados a nuestro servicio, trabajamos por brindar la mejor experiencia de compra a nuestros consumidores.
                        </p>
                        <dl class="text-muted">
                            <p>
                                <i class="mdi mdi-circle-medium text-primary"></i> Nuestra plataforma ha sido desarrollada por talento 100% boliviano.
                            </p>
                            <p>
                                <i class="mdi mdi-circle-medium text-primary"></i> Nos expandimos rápido a lo largo de las ciudades capitales de Bolivia.
                            </p>
                        </dl>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="container">
                        <img class="img-fluid h-50" src="../../public/img/about/2.png" alt="" lazy="loading">
                    </div>
                </div>
            </div>
            <div class="row p-5">
                <div class="col-lg-6">
                    <div class="container">
                        <img class="img-fluid h-50" src="https://i.ytimg.com/vi/d6xn5uflUjg/maxresdefault.jpg" alt="" lazy="loading">
                    </div>
                </div>
                <div class="col-lg-6 d-flex align-items-center">
                    <div class="container">
                        <h5 class="text-primary w-100">Trabajamos para hacerte la vida más fácil</h5>
                        <p class="text-muted">
                            Nuestro equipo trabaja constantemente por mejorar nuestro servicio y plataforma, ofreciendo nuevas funcionalidades que te permitan comprar más rápido, sin tantos clics y recibir tu producto en el menor tiempo posible.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "about-us",
        components: {
        },
    };
</script>
<style scoped>
    .img-text-centered {
        position: absolute;
        top: 50%;
        left: 50%;
        text-align: center;
        transform: translate(-50%, -50%);
    }
    .img-text-top-left {
        position: absolute;
        top: 15%;
        left: 10%;
    } 

    div.img-bg {
        background-image: url("../../public/img/about/banner.png"); 
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 50vh;
    }

    div.transbox {
        background-color: rgba(0, 0, 0, 0.5);
        height: 100%;
    }
</style>
