import { createRouter, createWebHistory } from "vue-router";

import DashboardLayout from "@/layout/DashboardLayout";
import AuthLayout from "@/layout/AuthLayout";

import Dashboard from "../views/Dashboard.vue";
import Tiendas from "../views/Tiendas.vue";
import Checkout from "../views/Checkout.vue";
// import Maps from "../views/Maps.vue";
import AboutUs from "../views/AboutUs.vue";
import Tienda from "../views/Tienda.vue";
import Productos from "../views/Productos.vue";
import Producto from "../views/Producto.vue";
import Pedidos from "../views/Pedidos.vue";
import Pedido from "../views/Pedido.vue";
import Profile from "../views/UserProfile.vue";
import Cart from "../views/Cart.vue";
import Tables from "../views/Tables.vue";

import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import NotFound from "../views/NotFound.vue";

import store from "../store";

const routes = [
	{
		path: "/",
		redirect: "/dashboard",
		component: DashboardLayout,
		children: [
		{
			path: "/dashboard",
			name: "dashboard",
			alias: "/",
			components: { default: Dashboard },
		},
		{
			path: "/tiendas",
			name: "tiendas",
			components: { default: Tiendas },
		},
		{
			path: "/tiendas/:tiendaId",
			name: 'tienda',
			component: Tienda,
			children: [
				{
					path: '',
					component: Productos
				},
				{
					path: 'productos/:productoId',
					name: 'producto',
					components: { default: Producto }
				}
			] 
		},
		{
			path: "/checkout",
			name: "checkout",
			components: { default: Checkout },
		},
		{
			path: "/pedidos",
			name: "pedidos",
			components: { default: Pedidos },
			beforeEnter: (to, from, next) => {
				if( ! store.getters['auth/authenticated'] ) {
					return next({
						name: 'dashboard'
					})
				}
				next()
			} 
		},
		{
			path: "/pedidos/:pedidoId",
			name: 'pedido',
			component: Pedido,
			beforeEnter: (to, from, next) => {
				if( ! store.getters['auth/authenticated'] ) {
					return next({
						name: 'dashboard'
					})
				}
				next();
			}
		},
		{
			path: "/nosotros",
			name: "nosotros",
			components: { default: AboutUs },
		},
		{
			path: "/profile",
			name: "profile",
			components: { default: Profile },
		},
		{
			path: "/cart",
			name: "cart",
			components: { default: Cart },
		},
		{
			path: "/tables",
			name: "tables",
			components: { default: Tables },
		},
		],
	},
	{
		path: "/",
		redirect: "login",
		component: AuthLayout,
		children: [
			{
				path: "/login",
				name: "login",
				components: { default: Login },
			},
			{
				path: "/register",
				name: "register",
				components: { default: Register },
			},
		],
	},
	{
		path: "/auth/:social/callback",
		name: "socialLogin",
		component: () => import('../views/SocialLogin.vue')
	},
	{ path: "/:pathMatch(.*)*", name: "NotFound", component: NotFound },
];

const router = createRouter({
	history: createWebHistory(),
	linkActiveClass: "active",
	routes,
});

export default router;
