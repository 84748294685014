<template>
    <div>
        <loading :active="isLoading" 
			color="#FF4200"
			:can-cancel="true" 
			:is-full-page="true"
		></loading>
        <div class="container pt-5 pb-5">
            <div class="card mt-4 border-0 shadow">
                <div class="card-body">
                    <h5 class="text-primary">Historial de pedidos</h5>
                    <order-table :orders="listPedidos" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios";
    import { mapGetters } from "vuex";
    import OrderTable from "../components/OrderTable.vue";
    
    import Loading from 'vue3-loading-overlay';
    import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';

    export default {
        name: "pedidos",
        components: {
            OrderTable,
            Loading
        },
        data() {
            return {
                loading: {
                    orders: true
                },
                pedidos: '',
            };
        },
        computed: {
			...mapGetters({
                user: "auth/user",
                authenticated: "auth/authenticated"
            }),
            listPedidos () {
                return this.pedidos.data;
            },
            isLoading() {
                return this.loading.orders ? true : false;
            }
		},
        methods: {
            getPedidos() {
                let vm = this;
                if( ! vm.authenticated ) {
                    return;
                }

                axios.get(process.env.VUE_APP_GOU_AFILIADO_URL + "api/v2/pedidos", {
                    params: {
                        users: vm.user.id_usuario
                    }
                })
                .then(function (response) {
                    vm.pedidos = response.data;
                    vm.loading.orders = false;
                })
                .catch(function (error) {
                    console.error(error.response);
                    vm.loading.orders = false;
                });
            },
        },
        created () {
            let vm = this;
            vm.getPedidos();
        }
    };
</script>

<style scoped>
</style>
