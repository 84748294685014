<template>
    <div class="container pt-5">
        <loading :active="isLoading" 
			color="#FF4200"
			:can-cancel="true" 
			:is-full-page="true"
		></loading>
        <div class="row">
            <div class="col-lg-12">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb bg-white small">
                        <li class="breadcrumb-item">
                            <router-link to="/">
                                Home
                            </router-link>
                        </li>
                        <li class="breadcrumb-item">
                            <router-link to="/tiendas">
                                Tiendas
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">{{ capitalizeNombre }}</li>
                    </ol>
                </nav>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-2">
                <img class="img-fluid rounded-circle" width="60" :src="info.logo" alt="Logo"/>
            </div>
            <div class="col-lg-10">
                <div class="row">
                    <div class="col-lg-12">
                        <h5>
                            {{ info.nombre }}
                        </h5>
                    </div>
                    <div class="col-lg-3">
                        <small><i class=""></i><b>Direccion: </b>{{ info.direccion }}</small><br>
                        <small><i class=""></i> {{ info.ciudad }}</small>
                    </div>
                    <div class="col-lg-3">
                        <small><i class=""></i><b>Envío: </b>{{ info.envio }}</small><br>
                        <small><i class=""></i><b>Costo: Bs </b>{{ info.costo_envio }}</small>
                    </div>
                    <div class="col-lg-3">
                        <small><i class=""></i><b>Horario de atención: </b>{{ info.horario_atencion }}</small><br>
                        <small>
                            <a :href="whatsappLink" class="text-primary">
                                <i class="mdi mdi-18px mdi-whatsapp mr-1"></i><b>WhatsApp: </b>{{ info.celular }}
                            </a>
                        </small>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <router-view></router-view>
    </div>
</template>
<script>
    import axios from "axios";
    import Loading from 'vue3-loading-overlay';
    import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
    export default {
        components: {
            Loading,
        },
        data() {
            return {
                loading: {
                    store: true,
                },
                store: {},
            };
        },
        computed: {
            tiendaId() {
                return this.$route.params.tiendaId;
            },
            info() {
                if(
                    this.store
                    && Object.keys(this.store).length === 0
                    && Object.getPrototypeOf(this.store) === Object.prototype
                ) {
                    return {
                        nombre: '',
                        direccion: '',
                        telefono: '',
                        celular: '',
                        logo: ''
                    }
                }
                return this.store.data;
            },
            capitalizeNombre() {
                if (!this.info.nombre) return ''
                const value = this.info.nombre.toString();
                return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
            },
            isLoading() {
                return this.loading.store ? true : false;
            },
            whatsappLink() {
                return `https://wa.me/+591${ this.info.celular }?text=Me%20interesa%20comprar%20sus%20productos`;
            }
        },
        methods: {
            getStore() {
                let vm = this;
                axios
                .get(process.env.VUE_APP_GOU_AFILIADO_URL + `api/v2/empresas/${vm.tiendaId}`)
                .then(function (response) {
                    vm.store = response.data;
                    vm.loading.store = false;
                })
                .catch(function (error) {
                    console.error('error cargar tienda', error.response);
                    vm.loading.store = false;
                });
            },
        },
        mounted() {
            this.getStore();
        }
    };
</script>
<style>

</style>
