<template>
	<div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
		<side-bar
		:background-color="sidebarBackground"
		short-title="Gou tienda"
		title="Gou tienda"
		@search="search"
		>
		<!-- <template v-slot:links>
			<sidebar-item
			:link="{
				name: 'Register',
				icon: 'ni ni-circle-08 text-pink',
				path: '/register',
			}"
			/>
		</template> -->
		</side-bar>
		<div class="main-content mt-5" :data="sidebarBackground">
			<div @click="toggleSidebar">
				<!-- your content here -->
				<router-view :searchText="searchText" :searchBy="searchBy"></router-view>
				<content-footer v-if="!$route.meta.hideFooter"></content-footer>
				<a 
					href="javascript:void(0)" 
					class="btn-flotante rounded-circle d-flex justify-content-center align-items-center text-decoration-none"
					@click="seeOrder()"
				>
					<i class="ni ni-cart text-white"></i>
					<span class="badge badge-light shadow">{{ totalProducts }}</span>
					<span class="sr-only">Cantidad de items</span>
				</a>
			</div>
		</div>
	</div>
</template>
<script>
	// import DashboardNavbar from "./DashboardNavbar.vue";
	import ContentFooter from "./ContentFooter.vue";
	import { mapGetters } from "vuex";
	export default {
	components: {
		// DashboardNavbar,
		ContentFooter,
	},
	data() {
		return {
			sidebarBackground: "vue", //vue|blue|orange|green|red|primary,
			searchText: null,
			searchBy: 'product'
		};
	},
	computed: {
		...mapGetters([
			"shoppingCart",
			"totalProducts",
			"totalPayment",
			"isEmptyCart",
		]),
	},
	methods: {
		toggleSidebar() {
		if (this.$sidebar.showSidebar) {
			this.$sidebar.displaySidebar(false);
		}
		},
		search(params) {
			this.searchText = params.searchText;
			this.searchBy = params.searchBy;
		},
		seeOrder() {
			this.$router.push('/checkout')
		}
	},
	};
</script>
<style lang="scss">
  	/* boton flotante */
	.btn-flotante {
		background-color: #ff4000b9; /* Color de fondo */
		padding: .5rem; /* Relleno del boton */
		width: 4rem;
		height: 4rem;
		position: fixed;
		bottom: 40px;
		right: 40px;
		transition: all 300ms ease 0ms;
		box-shadow: 0px 8px 15px rgba(150, 33, 33, 0.5);
		z-index: 99;
	}

	.btn-flotante i {
		font-size: 2rem;
	}

	.btn-flotante span {
		font-size: 1rem;
		position: absolute;
		left: 2.9em;
		bottom: 2.9em;
	}

	.btn-flotante:hover {
		background-color: #FF4200; /* Color de fondo al pasar el cursor */
		box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.5);
		transform: translateY(-7px);
	}

	@media only screen and (max-width: 600px) {
		.btn-flotante {
			font-size: 14px;
			padding: 12px;
			bottom: 20px;
			right: 20px;
		}
	}
	/* end */
</style>
