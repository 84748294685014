<template>
    <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
            <div class="card shadow-lg border-0">
                <div class="card-header bg-transparent pb-5">
                    <h3 class="card-title text-center">Iniciar sesión</h3>
                    <base-social-login/>
                </div>
                <div class="card-body px-lg-5 py-lg-5">
                    <div class="text-center text-muted mb-4">
                        <small>O inicia sesión con credenciales </small>
                    </div>
                    <form role="form" @submit.prevent="submit">
                        <base-input
                            formClasses="input-group-alternative mb-3"
                            placeholder="Email"
                            addon-left-icon="mdi mdi-18px mdi-email-outline"
                            :valid="v$.model.email.$errors.length > 0 ? false : null"
                            v-model="model.email"
                        >
                        </base-input>
                        <base-input
                            formClasses="input-group-alternative mb-3"
                            placeholder="Contraseña"
                            type="password"
                            addon-left-icon="mdi mdi-18px mdi-lock-outline"
                            :valid="v$.model.password.$errors.length > 0 ? false : null"
                            v-model="model.password"
                        >
                        </base-input>
                        <base-checkbox class="custom-control-alternative">
                            <span class="text-muted">Recuerda mis datos</span>
                        </base-checkbox>
                        <div class="text-center">
                            <button type="submit" class="btn btn-primary my-4 btn-block" :disabled="sending.signIn">
                                <div v-if="!sending.signIn">INGRESAR</div>
                                <div v-else>
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Iniciando sesión...
                                </div>
                            </button>
                        </div>
                        <div class="text-center">
                            <small>Todavia no tienes una cuenta?
                                <router-link class="text-primary" to="/register">
                                    Registrate!
                                </router-link>
                            </small>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapActions } from "vuex";
    import useVuelidate from '@vuelidate/core'
    import { required, email } from '@vuelidate/validators'
    import helpers from "../plugins/helpers"
    import BaseSocialLogin from "../components/BaseSocialLogin.vue";

    export default {
        name: "login",
        setup () {
            return { v$: useVuelidate() }
        },
        components: {
            BaseSocialLogin,
        },
        data() {
            return {
                sending: {
                    signIn: false,
                },
                model: {
                    email: "",
                    password: "",
                },
            };
        },
        validations () {
            return {
                model: {
                    email: { required, email }, // Matches this.contact.email
                    password: { required }
                }
            }
        },
        methods: {
            ...mapActions({
                signIn: "auth/signIn"
            }),
            async submit() {
                const result = await this.v$.$validate()
                if (!result) {
                    helpers.showErrorsformValidation(this.v$.$errors, this.$notify);
                    return;
                }
                console.log('submitted')
                this.sending.signIn = true;
                this.signIn(this.model).then((response) => {
                    console.log('signIn', response);
                    this.$router.replace({
                        name: 'dashboard'
                    })
                    this.sending.signIn = false;
                }).catch((error) => {
                    console.log('submit login error', error.response);
                    this.$notify({
                        group: "app",
                        type: "error",
                        duration: 4000,
                        text: ( error.response.data.error ).toUpperCase()
                    })
                    this.sending.signIn = false;
                });
            }
        },
        mounted() {
            // this.v$.$touch(); // init validation form before submit
        }
    };
</script>
<style></style>
