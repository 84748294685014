<template>
    <div class="text-muted text-center mt-2 mb-3">
        <small>Iniciar sesión con</small>
    </div>
    <div class="btn-wrapper text-center">
        <a href="javascript:void(0)" class="btn btn-neutral btn-icon" data-toggle="tooltip" title="Ingresar con Google" v-if="loggingIn.google">
            <span class="spinner-border spinner-border-sm text-primary" role="status" aria-hidden="true"></span>
            <span class="btn-inner--text">Iniciando...</span>
        </a>
        <a href="javascript:void(0)" class="btn btn-neutral btn-icon" data-toggle="tooltip" title="Ingresar con Google" @click="socialLogin('google')" v-else>
            <span class="btn-inner--icon"
                ><img src="img/icons/common/google.svg"
            /></span>
            <span class="btn-inner--text">Google</span>
        </a>
        <a href="javascript:void(0)" class="btn btn-neutral btn-icon" data-toggle="tooltip" title="Ingresar con Google" v-if="loggingIn.facebook">
            <span class="spinner-border spinner-border-sm text-primary" role="status" aria-hidden="true"></span>
            <span class="btn-inner--text">Iniciando...</span>
        </a>
        <a href="javascript:void(0)" class="btn btn-neutral btn-icon" data-toggle="tooltip" title="Ingresar Facebook" @click="socialLogin('facebook')" v-else>
            <span class="btn-inner--icon"
                ><img src="img/icons/common/facebook.svg"
            /></span>
            <span class="btn-inner--text">Facebook</span>
        </a>
    </div>
</template>
<script>
    import axios from "axios";
    export default {
        name: "base-social-login",
        props: {
            
        },
        data() {
            return {
                loggingIn: {
                    google: false,
                    facebook: false
                },
            }
        },
        methods: {
            socialLoginRedirect(social) {
                return new Promise( (resolve, reject) => {
                    axios.get(`https://afiliado.gou.com.bo/api/v2/auth/${social}/redirect`)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    })
                })
            },
            socialLogin(social) {
                console.log(social);
                this.loggingIn[social] = true;
                this.socialLoginRedirect(social).then((response) => {
                    console.log('socialLogin response', response.data.url);
                    if(response.data.url) {
                        window.location.href = response.data.url;
                    }
                    this.loggingIn[social] = false;
                    // window.open(response.data.url, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
                }).catch((error) => {
                    console.log('social login error', error);
                    this.loggingIn[social] = false;
                })
            },
        }
    };
</script>
<style>

</style>
