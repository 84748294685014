<template>
    <div>
        <base-table :data="orders">
            <template v-slot:columns>
                <th width="35%">Fecha</th>
                <th width="20%">Nro</th>
                <th width="15%">total</th>
                <th width="20%">Estado</th>
                <th width="10%"></th>
            </template>
            <template v-slot:default="row">
                <th scope="row" class="text-left">
                    {{ row.item.fecha }}
                </th>
                <td>
                    {{ row.item.nro }}
                </td>
                <td class="text-right">
                    {{ numberFormat( row.item.total ) }}
                </td>
                <td class="text-uppercase">
                    <span class="status-circle" :class="bgStatus(row.item.estado)"></span>
                    {{ row.item.estado }}
                </td>
                <td>
                    <router-link :to="{ name: 'pedido', params: { pedidoId: row.item.id } }" class="text-primary">
                        <i class="mdi mdi-18px mdi-eye"></i> Ver detalle
                    </router-link>
                </td>
            </template>
        </base-table>
    </div>
</template>
<script>
    import helpers from '../plugins/helpers';

    export default {
        name: "order-table",
        props: {
            orders: {
                type: Array,
                description: "orders"
            }
        },
        methods: {
            bgStatus ( status ) {
                return status == 'pendiente' ? 'bg-warning' : status == 'cancelado' ? 'bg-danger' : status == 'finalizado' ? 'bg-success' : 'bg-secondary';
            },
            numberFormat ( value ) {
                return helpers.numberFormat(value, 2);
            }
        }
    };
</script>
<style scoped>
    .status-circle { 
        width: .7em;
        height: .7em;
        -moz-border-radius: 50%; 
        -webkit-border-radius: 50%; 
        border-radius: 50%;
        display: inline-block;
    }
</style>
