<template>
    <div class="container mt-3">
        <!-- coponent product -->
        <div class="row">
            <div class="col-lg-12">
                <i class="fa fa-border-all"></i> Mostrando {{ pagination.from }} - {{ pagination.to }} de {{ pagination.total }} resultados
            </div>
        </div>
        <hr class="mt-2 mb-3">
        <div class="row">
            <div
                class="col-lg-3 col-md-4 mb-4"
                v-for="(item, index) in listProducts"
                :key="index"
            >
                <card-product :item="item" @add-cart="addCart" @show-item="showItem" @detail-item="detailItem" />
            </div>
            <div
                class="col-lg-12"
            >
                <base-pagination :perPage="pagination.perPage" :total="pagination.total" :value="pagination.currentPage" align="center" @input="paginate"></base-pagination>
            </div>
        </div>
        
        <modal v-model:show="modals.modal1" modal-classes="modal-dialog-centered">
            <div class="row">
                <div class="col-lg-5">
                <img class="img-fluid" :src="formatProductImage(getItemActive.producto.imagen)">
                </div>
                <div class="col-lg-7">
                <h4>{{ getItemActive.producto.nombre }}</h4>
                <h3 class="text-pink">Bs {{ getItemActive.producto.precio }}</h3>
                <p>{{ getItemActive.producto.descripcion }}</p>
                <div class="input-group" v-show="itemExists">
                    <div class="input-group-prepend">
                    <button class="btn btn-secondary" type="button">
                        <i class="fa fa-minus"></i>
                    </button>
                    </div>
                    <div class="form-control text-center">
                    {{ getItemActive.cantidad }}
                    </div>
                    <div class="input-group-append">
                    <button class="btn btn-secondary" type="button" @click="addCart(getItemActive)">
                        <i class="fa fa-plus"></i>
                    </button>
                    </div>
                </div>
                </div>
            </div>              
            <template v-slot:footer>
                <button v-if="itemExists" class="btn btn-danger" @click="removeCart(getItemActive)">
                Quitar del carrito
                </button>
                <button v-else class="btn btn-primary" @click="addCart(getItemActive)">
                Agregar al carrito
                </button>
                <base-button type="secondary" class="ml-auto" @click="modals.modal1 = false">
                <i class="fa fa-times"></i> Cerrar
                </base-button>
            </template>
        </modal>
        <!-- end -->
    </div>
</template>
<script>
import axios from "axios";
export default {
    components: {
        
    },
    data() {
        return {
            // product component
            products: {},
            pagination: {
                currentPage: 1,
                from: 0,
                to: 0,
                perPage: 16,
                total: 0
            },
            modals: {
                modal1: false
            },
            itemActive: {},
            // end
        };
    },
    computed: {
        tiendaId() {
            return this.$route.params.tiendaId;
        },
        // product component
        listProducts() {
            if( !this.products.data ) return [];
            return this.products.data.map( item => {
                item.promocion = JSON.parse(item.promocion) 
                return item;
            });
        },
        totalItems() {
            return this.products.length;
        },
        getItemActive() {
            if( this.itemActive && Object.keys(this.itemActive).length === 0 && Object.getPrototypeOf(this.itemActive) === Object.prototype ) return {
                producto: {
                nombre: null,
                imagen: null
                }
            }
            return this.itemActive;
        },
        itemExists() {
            return this.$store.getters.productExists(this.itemActive.id);
        },
        // end
    },
    methods: {
        // product component
        getOnlineProducts(params = null) {
            console.log('getOnlineProducts', params)
            let vm = this;
            axios
            .get(process.env.VUE_APP_GOU_AFILIADO_URL + "api/v2/productOnlinePost", {
                params
            })
            .then(function (response) {
                console.log("getProductsOnline", response);
                vm.products = response.data;
                vm.pagination.currentPage = response.data.meta.current_page;
                vm.pagination.from = response.data.meta.from;
                vm.pagination.to = response.data.meta.to;
                vm.pagination.perPage = response.data.meta.per_page;
                vm.pagination.total = response.data.meta.total;
                })
            .catch(function (error) {
                console.log('error en cargar productos', error.response);
            });
        },
        paginate(page) {
            this.getOnlineProducts({ page, stores: this.tiendaId });
        },
        showItem(item) {
            if( this.$store.getters.productExists(item.id) ) {
                this.itemActive = this.$store.getters.productById(item.id);
            } else {
                this.itemActive = item;
            }
            this.modals.modal1 = true;
        },
        detailItem(item) {
            this.$router.push({ name: 'producto', params: { productoId: item.id } });
        },
        addCart(item) {
            if(this.$store.getters.productExists(item.id)) {
                const cartItem = this.$store.getters.productById(item.id);
                this.$store.dispatch("updateCart", {id: item.id, cantidad: cartItem.cantidad + 1});
            } else {
                item.cantidad = 1;
                this.$store.dispatch("addCart", item);
            }
            this.$notify({
                group: "app",
                type: "success",
                text: "Agregado al carrito",
                ignoreDuplicates: true
            });
        },
        formatProductImage: function (value) {
            if (!value) return "defaultSrc";
            return process.env.VUE_APP_GOU_AFILIADO_URL + value.split(",")[0];
        },
        // end
    },
    mounted() {
        this.getOnlineProducts({ stores: this.tiendaId });
    }
};
</script>
<style>

</style>
