import { onUnmounted, onMounted, ref } from 'vue'

export function useGeolocation() {
	const coords = ref({ latitude: -16.48904187002302, longitude: -68.12475882411961 })
	const isSupported = 'navigator' in window && 'geolocation' in navigator

	let watcher = null
	onMounted(() => {
		if (isSupported)
		watcher = navigator.geolocation.watchPosition(
			position => (coords.value = position.coords)
		)
	})
	onUnmounted(() => {
		if (watcher) navigator.geolocation.clearWatch(watcher)
	})

	return { coords, isSupported }
}
