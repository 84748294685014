<template>
    <div class="container">
        <detail-product :item="info" />
    </div>
</template>
<script>
import axios from "axios";
export default {
    name: "product",
    data() {
        return {
            product: {}
        };
    },
    computed: {
        productoId() {
            return this.$route.params.productoId;
        },
        info() {
            if(
                this.product
                && Object.keys(this.product).length === 0
                && Object.getPrototypeOf(this.product) === Object.prototype
            ) {
                return {
                    producto: '',
                    promocion: ''
                }
            }
            return this.product.data;
        }
    },
    methods: {
        getProductOnline() {
            let vm = this;
            axios
            .get(process.env.VUE_APP_GOU_AFILIADO_URL + `api/v2/productOnlinePost/${vm.productoId}`)
            .then(function (response) {
                vm.product = response.data;
            })
            .catch(function (error) {
                console.error(error);
            });
        },
    },
    mounted() {
        this.getProductOnline();
    }
};
</script>
<style></style>
