<template>
    <div class="container pt-5">
        <loading :active="isLoading" 
			color="#FF4200"
			:can-cancel="true" 
			:is-full-page="true"
		></loading>
        <div class="row">
            <div class="col-lg-12">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb bg-white small">
                        <li class="breadcrumb-item">
                            <router-link to="/">
                                Home
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">{{ $route.name }}</li>
                    </ol>
                </nav>
            </div>
        </div>
        <div class="row text-center">
            <div class="col-xl-3 col-sm-6 mb-5" v-for="(item, index) in listStores" :key="index">
                    <div class="bg-white h-100 rounded shadow py-5 px-4">
                        <a href="javascript:void(0)" class="text-decoration-none" @click="goToTienda(item.url)">
                            <img :src="item.logo" alt="Logo" width="100" class="rounded-circle mb-3 img-thumbnail shadow-sm img-icon">
                            <h5 class="text-primary text-uppercase mb-0">{{ item.nombre }}</h5>
                            <span class="small text-muted">{{ item.descripcion }}</span>
                        </a>
                        <ul class="mb-0 mt-3 list-inline">
                            <li class="list-inline-item"><a href="#" class="social-link"><i class="mdi mdi-facebook"></i></a></li>
                            <li class="list-inline-item"><a href="#" class="social-link"><i class="mdi mdi-twitter"></i></a></li>
                            <li class="list-inline-item"><a href="#" class="social-link"><i class="mdi mdi-instagram"></i></a></li>
                            <li class="list-inline-item"><a href="#" class="social-link"><i class="mdi mdi-linkedin"></i></a></li>
                        </ul>
                    </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
// Import component
import Loading from 'vue3-loading-overlay';
// Import stylesheet
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';

export default {
    components: {
        Loading,
    },
    data() {
        return {
            loading: {
                stores: true
            },
            stores: {},
            page: 1,
            list: [],
        };
    },
    computed: {
        listStores() {
            return this.stores.data;
        },
        isLoading() {
            return this.loading.stores ? true : false;
        }
    },
    methods: {
        getStores(params = null) {
            let vm = this;
            axios
            .get(process.env.VUE_APP_GOU_AFILIADO_URL + "api/v2/empresas", {
                params
            })
            .then(function (response) {
                vm.stores = response.data;
                vm.loading.stores = false;
            })
            .catch(function (error) {
                console.error(error);
            });
        },
        goToTienda(id) {
            this.$router.push('/tiendas/' + id);
        }
    },
    mounted() {
        this.getStores({
            services: "store"
        });
    },
};
</script>
<style>
    .social-link {
        width: 30px;
        height: 30px;
        border: 1px solid #ddd;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #666;
        border-radius: 50%;
        transition: all 0.3s;
        font-size: 0.9rem;
    }

    .social-link:hover, .social-link:focus {
        background: #ddd;
        text-decoration: none;
        color: #555;
    }

    .img-icon {
        width: 7rem !important;
        height: 7rem !important;
        object-fit: cover;
    }
</style>
