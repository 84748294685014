const showErrorsformValidation = (errors, notify) => {
    errors.forEach((error) => {
        console.log(error.$property, error.$message);
        notify({
            group: "app",
            type: "error",
            duration: 6000,
            text: ( error.$property + ' ' + error.$message ).toUpperCase()
        })
    })
}

// Round decimal
(function() {

    function decimalAdjust(type, value, exp) {
        // Si el exp no está definido o es cero...
        if (typeof exp === 'undefined' || +exp === 0) {
            return Math[type](value);
        }
        value = +value;
        exp = +exp;
        // Si el valor no es un número o el exp no es un entero...
        if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
            return NaN;
        }
        // Shift
        value = value.toString().split('e');
        value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
        // Shift back
        value = value.toString().split('e');
        return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
    }
  
    // Decimal round
    if (!Math.round10) {
        Math.round10 = function(value, exp) {
            return decimalAdjust('round', value, exp);
        };
    }
    // Decimal floor
    if (!Math.floor10) {
        Math.floor10 = function(value, exp) {
            return decimalAdjust('floor', value, exp);
        };
    }
    // Decimal ceil
    if (!Math.ceil10) {
        Math.ceil10 = function(value, exp) {
            return decimalAdjust('ceil', value, exp);
        };
    }
})();  
// end

// numberFormat
const numberFormat = (value = 0, decimal = 0) => {
    return new Intl.NumberFormat('en-IN', {
        minimumFractionDigits: decimal
    }).format(Math.round10(value, -Math.abs(decimal)).toFixed(decimal) );
}
// end

export default {
    showErrorsformValidation,
    numberFormat
};