<template>
	<nav
		class="navbar navbar-horizontal navbar-expand-lg navbar-light bg-white border-bottom fixed-top"
		id="sidenav-main"
	>
		<navbar-toggle-button @click="showSidebar">
			<span class="navbar-toggler-icon"></span>
		</navbar-toggle-button>
		<router-link class="navbar-brand" to="/">
			<img :src="logo" class="navbar-brand-img" alt="Gou tienda" />
		</router-link>

		<slot name="mobile-right">
			<ul class="nav align-items-center d-md-none">
				<li class="nav-item" title="Carrito">
					<router-link class="nav-link text-primary link-cart" to="/checkout">
						<i class="mdi mdi-24px mdi-cart-outline"></i>
						<span class="badge badge-light shadow position-absolute notify-cart">{{ totalProducts }}</span>
					</router-link>
				</li>
			</ul>
		</slot>
		<slot></slot>
		<div
			v-show="$sidebar.showSidebar"
			class="navbar-collapse collapse show justify-content-end"
			id="sidenav-collapse-main"
		>
			<div class="navbar-collapse-header d-md-none">
				<div class="row">
				<div class="col-6 collapse-brand">
					<router-link to="/">
					<img :src="logo" />
					</router-link>
				</div>
				<div class="col-6 collapse-close">
					<navbar-toggle-button @click="closeSidebar"></navbar-toggle-button>
				</div>
				</div>
			</div>
			<form class="form-inline my-2 my-lg-0 min-w-50" onsubmit="return false;">
				<div class="input-group shadow w-100">
					<div class="input-group-prepend">
						<base-dropdown>
							<template v-slot:title>
								<base-button type="light" class="dropdown-toggle">
									{{ activeSearchBy.label }}
								</base-button>
							</template>
							<a
								class="dropdown-item"
								href="javascript:void(0)"
								@click="changeSearchByValue(item)"
								v-for="(item, index) in searchBy"
								:key="index"
								>{{ item.label }}</a
							>
						</base-dropdown>
					</div>
					<input
						class="form-control border-0 p-2 rounded-0"
						type="search"
						:placeholder="'Buscar por ' + activeSearchBy.label.toLowerCase()"
						aria-label="Search"
						v-model="searchText"
					/>
					<div class="input-group-append">
						<button
							class="btn btn-primary"
							type="button"
							@click="search()"
						>
							<i class="fas fa-search"></i>
						</button>
					</div>
				</div>
			</form>
			<ul class="navbar-nav">
				<slot name="links">
					<sidebar-item
						:link="{
							name: 'Tiendas',
							path: '/tiendas',
							icon: 'mdi mdi-24px mdi-store'
						}"
					/>
					<sidebar-item
						:link="{
							name: 'Nosotros',
							path: '/nosotros',
							icon: 'mdi mdi-24px mdi-account-multiple-outline'
						}"
					/>
					<sidebar-item v-show="!authenticated"
						:link="{
							name: 'Ingresar',
							path: '/login',
							icon: 'mdi mdi-24px mdi-account-plus-outline'
						}"
					/>
				</slot>
				<li class="nav-item dropdown" title="Usuario" v-show="authenticated">
					<a class="nav-link dropdown-toggle text-primary" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						<i class="mdi mdi-24px mdi-account-circle mr-0"></i>
					</a>
					<div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
						<h6 class="dropdown-header">{{ user.nombre }}</h6>
						<router-link class="dropdown-item" to="/pedidos">Mis pedidos</router-link>
						<div class="dropdown-divider"></div>
						<a class="dropdown-item" href="javascript:void(0)" @click="signOut">
							<i class="mdi mdi-24px mdi-logout mr-0"></i> Cerrar sesion
						</a>
					</div>
				</li>
				<li class="nav-item" title="Carrito">
					<a
						href="javascript:void(0)"
						class="nav-link text-primary link-cart"
						@click="modals.modal2 = isEmptyCart ? false : true"
					>
						<i class="mdi mdi-24px mdi-cart-outline"></i>
						<span class="badge badge-light shadow position-absolute notify-cart">{{ totalProducts }}</span>
					</a>
				</li>
			</ul>
			<modal v-model:show="modals.modal2" modal-classes="modal-dialog-centered">
				<template v-slot:header>
					<h1 class="modal-title" id="modal-title-notification">
						<i class="fas fa-shopping-cart"></i> Pedido
					</h1>
				</template>
				<div class="row">
					<div class="col-lg-12">
						<table class="table table-hover table-sm">
							<tbody>
								<tr v-for="(item, index) in shoppingCart" :key="index">
								<td class="w-25">
									<input
									type="number"
									class="form-control text-center"
									v-model="item.cantidad"
									/>
								</td>
								<td class="w-50">
									{{ item.producto.nombre }}
									<h5>Bs {{ numberFormat( item.precio ) }}</h5>
								</td>
								<td class="w-25 text-right">
									<a
									href="javascript:void(0)"
									class="text-danger"
									@click="$store.dispatch('removeCart', index)"
									>
									<i class="fa fa-trash"></i>
									</a>
								</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="col-lg-12">
						<h2 class="d-flex justify-content-between">
						<span>Total</span><span>Bs {{ numberFormat( totalPayment ) }}</span>
						</h2>
					</div>
				</div>
				<template v-slot:footer>
				<base-button type="primary" @click="seeOrder()">Confirmar pedido</base-button>
				<base-button
					type="light"
					class="ml-auto"
					@click="cancelOrder()"
				>
					Cancelar pedido
				</base-button>
				</template>
			</modal>
		</div>
	</nav>
</template>
<script>
	import NavbarToggleButton from "@/components/NavbarToggleButton";
	import { mapGetters, mapActions } from "vuex";
	import helpers from "../../plugins/helpers";
	export default {
		name: "sidebar",
		components: {
			NavbarToggleButton,
		},
		props: {
			logo: {
			type: String,
			default: "img/brand/icon.png",
			description: "Sidebar app logo",
			},
			autoClose: {
			type: Boolean,
			default: true,
			description:
				"Whether sidebar should autoclose on mobile when clicking an item",
			},
		},
		data() {
			return {
			modals: {
				modal2: false,
			},
			searchText: null,
			searchBy: [
				{
					label: "Producto",
					value: "product",
					active: true,
				},
				{
					label: "Categoría",
					value: "category",
					active: false,
				},
				{
					label: "Tienda",
					value: "store",
					active: false,
				}
			],
			};
		},
		provide() {
			return {
			autoClose: this.autoClose,
			};
		},
		computed: {
			...mapGetters({
				shoppingCart: "shoppingCart",
				shoppingCartSend: "shoppingCartSend",
				totalProducts: "totalProducts",
				totalPayment: "totalPayment",
				isEmptyCart: "isEmptyCart",
				authenticated: "auth/authenticated",
				user: "auth/user",
			}),
			activeSearchBy() {
				return this.searchBy.find((item) => item.active == true);
			},
		},
		watch: {
			isEmptyCart(newValue) {
				if (newValue) {
					this.modals.modal2 = false;
				}
			},
		},
		methods: {
			...mapActions({
				signOutAction: 'auth/signOut'
			}),
			closeSidebar() {
				this.$sidebar.displaySidebar(false);
			},
			showSidebar() {
				this.$sidebar.displaySidebar(true);
			},
			numberFormat(value) {
				return helpers.numberFormat(value, 2)
			},
			search() {
				if (!this.searchText) return;
				this.$emit("search", {
					searchText: this.searchText,
					searchBy: this.activeSearchBy.value,
				});
			},
			seeOrder() {
				this.modals.modal2 = false;
				this.$router.push('/checkout')
			},
			cancelOrder() {
				this.modals.modal2 = false;
				this.$store.dispatch("emptyCart");
			},
			changeSearchByValue(item) {
				if (item.active) return;
				this.searchBy.map((param) => (param.active = false));
				item.active = true;
			},
			signOut() {
				this.signOutAction().then(() => {
					this.$router.replace({
						name: 'dashboard'
					})
				})
			}
		},
		beforeUnmount() {
			if (this.$sidebar.showSidebar) {
			this.$sidebar.showSidebar = false;
			}
		},
	};
</script>

<style scoped>
	.min-w-50 {
		min-width: 50%;
	}
	/* icons */
	.cart-basket {
		font-size: 0.6rem;
		position: absolute;
		top: -2px;
		right: 12px;
		width: 20px;
		height: 20px;
		color: #fff;
		background-color: #418deb;
		border-radius: 50%;
	}

	.notify-cart {
		right: 3em;
	}

	@media all and (max-width: 480px) {
		.btn-sm-block {
			width: 100%;
			display: block;
		}
		.notify-cart {
			right: 2em;
		}
	}
</style>
