<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <dl>
                    <dt v-if="order.nro">Nro. de pedido:</dt>
                    <dd v-if="order.nro">{{ order.nro }}</dd>
                    <dt v-if="order.fecha">Fecha:</dt>
                    <dd v-if="order.fecha">{{ order.fecha }}</dd>
                    <dt>Nombre:</dt>
                    <dd>{{ order.razon_social }}</dd>
                    <dt>Total:</dt>
                    <dd>Bs {{ numberFormat( total ) }}</dd>
                    <dt>Método de pago:</dt>
                    <dd>{{ order.metodo_pago.nombre }}</dd>
                </dl>
            </div>
            <div class="col-lg-6">
                <div class="card border-0 shadow h-100">
                    <div class="card-body">
                        <h6 class="text-primary text-uppercase">Tu pedido</h6>
                        <div class="table-responsive">
                            <table class="table table-sm">
                                <thead>
                                    <th>PRODUCTO</th>
                                    <th class="text-right">SUBTOTAL</th>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in order.items" :key="index">
                                        <td>
                                            <b>{{ item.cantidad }} x </b> {{ getProductName(item) }}<br/>
                                            <small class="text-muted">{{ getStoreName(item) }}</small>
                                        </td>
                                        <td class="text-right">Bs {{ numberFormat( item.precio * item.cantidad ) }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr class="text-right">
                                        <th><span class="h4"> TOTAL</span></th>
                                        <th><span class="h4"> Bs {{ numberFormat( total ) }}</span></th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="card border-0 shadow h-100">
                    <div class="card-body">
                        <h6 class="text-primary text-uppercase">Dirección de envío</h6>
                        <div class="table-responsive">
                            <table class="table table-sm">
                                <tbody>
                                    <tr>
                                        <td colspan="2">
                                            {{ order.direccion }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>Nro. de puerta:</b></td>
                                        <td>{{ order.nro_puerta }}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Zona:</b></td>
                                        <td>{{ order.zona }}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Ciudad:</b></td>
                                        <td>{{ order.ciudad.nombre }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 d-flex justify-content-around">
                <slot name="actions"></slot>
                
            </div>
        </div>
    </div>
</template>
<script>
    import helpers from '../plugins/helpers';

    export default {
        name: "detail-order",
        props: {
            order: {
                type: Object,
                description: "order",
                required: true
            },
        },
        computed: {
            total() {
                return this.order.total != undefined ? this.order.total : this.order.items.reduce((a, b) => a + parseFloat(b.precio * b.cantidad), 0);
            },
        },
        methods: {
            getProductName (item) {
                if(!item) {
                    return '';
                }

                if( item.product_online == undefined ) {
                    return item.producto.nombre;
                }
                
                return item.product_online.producto.nombre;
            },
            getStoreName (item) {
                console.log(item)
                if(!item) {
                    return '';
                }

                if( item.product_online == undefined ) {
                    return item.tienda.nombre;
                }

                return item.product_online.tienda.nombre;
            },
            numberFormat (value) {
                return helpers.numberFormat(value, 2);
            },
        }
    };
</script>
<style scoped>

</style>
