/*!

=========================================================
* Vue Argon Dashboard - v2.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/vue-argon-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/vue-argon-dashboard/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import ArgonDashboard from "./plugins/argon-dashboard";
import Notifications from "@kyvg/vue3-notification";

import "element-plus/lib/theme-chalk/index.css";
import store from "./store";
// auth
// require('@/store/subscriber');
// store.dispatch('auth/attempt', localStorage.getItem('token'))
// end

import jQuery from "jquery";
window.$ = window.jQuery = jQuery;

import "popper.js";
import "bootstrap";
import "./assets/scss/style.scss";

require('@/store/subscriber');

store.dispatch('auth/attempt', localStorage.getItem('token'))
// esto para esperar a que el usuario se carge y recien se inicie la app, otra opcion es poner un loader en el sidebar login 
.then(() => {
    const appInstance = createApp(App).use(store);
    appInstance.use(router);
    appInstance.use(ArgonDashboard);
    appInstance.use(Notifications);
    appInstance.mount("#app");
})

