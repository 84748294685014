<script>
    /* eslint-disable no-undef */
    import { ref, onMounted, onUnmounted, watch } from 'vue'
    import { useGeolocation } from './../plugins/useGeolocation'
    import { Loader } from '@googlemaps/js-api-loader'

    const GOOGLE_MAPS_API_KEY = 'AIzaSyBrK_yJhghK8FV2b3YwoaATOYT19W-Xu9k'

    export default {
        name: 'base-map',
        setup() {
            const { coords } = useGeolocation()

            const currentPosition = ref(null)

            const loader = new Loader({ apiKey: GOOGLE_MAPS_API_KEY })
            const mapDiv = ref(null)
            let map = ref(null)
            let marker = ref(null)
            let clickListener = null
            let infoWindow = null;

            onMounted(async () => {
                await loader.load()
                
                currentPosition.value = {
                    lat: coords.value.latitude,
                    lng: coords.value.longitude
                }

                infoWindow = new google.maps.InfoWindow();

                map.value = new google.maps.Map(mapDiv.value, {
                    center: currentPosition.value,
                    zoom: 14
                })

                marker.value = new google.maps.Marker({
                    position: currentPosition.value,
                    map: map.value,
                    draggable: true,
                    title: 'Mi ubicación',
                    animation: google.maps.Animation.DROP,
                });

                clickListener = map.value.addListener(
                    'click',
                    ({ latLng: { lat, lng } }) =>
                    (currentPosition.value = { lat: lat(), lng: lng() })
                )

                // extra
                const locationButton = document.createElement("button");

                locationButton.textContent = "En contrar mi ubicación";
                locationButton.style.backgroundColor = "#fff";
                locationButton.style.border = "2px solid #fff";
                locationButton.style.borderRadius = "2px";
                locationButton.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
                locationButton.style.cursor = "pointer";
                locationButton.style.marginTop = "8px";
                locationButton.style.marginBottom = "22px";
                locationButton.style.color = "rgb(25,25,25)";
                locationButton.style.fontFamily = "Roboto,Arial,sans-serif";
                locationButton.style.fontSize = "16px";
                locationButton.style.lineHeight = "38px";
                locationButton.style.paddingLeft = "5px";
                locationButton.style.paddingRight = "5px";
                locationButton.style.textAlign = "center";
                locationButton.title = "Click para encontrar tu ubicación";
                locationButton.classList.add("custom-map-control-button");
                map.value.controls[google.maps.ControlPosition.TOP_CENTER].push(locationButton);
                locationButton.addEventListener("click", () => {
                    // Try HTML5 geolocation.
                    if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition((position) => {
                                currentPosition.value = {
                                    lat: position.coords.latitude,
                                    lng: position.coords.longitude,
                                };

                                /* infoWindow.setPosition(currentPosition.value);
                                infoWindow.setContent("Location found.");
                                infoWindow.open(map.value); */
                                map.value.setCenter(currentPosition.value);
                            },
                            () => {
                                handleLocationError(true, infoWindow, map.value.getCenter());
                            }
                        );
                    } else {
                        // Browser doesn't support Geolocation
                        handleLocationError(false, infoWindow, map.value.getCenter());

                    }
                });

            })

            onUnmounted(async () => {
                if (clickListener) clickListener.remove()
            })

            watch([map, currentPosition], () => {

                if (map.value && currentPosition.value != null) {
                    marker.value.setPosition(currentPosition.value);
                }

            })

            const handleLocationError = (browserHasGeolocation, infoWindow, pos) => {
                infoWindow.setPosition(pos);
                infoWindow.setContent(
                    browserHasGeolocation
                    ? "Error: The Geolocation service failed."
                    : "Error: Your browser doesn't support geolocation."
                );
                infoWindow.open(map.value);
            }

            return { currentPosition, mapDiv }
        },
        watch: {
            currentPosition ( newValue ) {
                if( ! newValue ) return;
                this.$emit('position', newValue);
            }
        }
    }
</script>

<template>
    <div ref="mapDiv" style="width: 100%; height: 50vh" />
</template>
