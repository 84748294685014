<template>
    <div>
        <div class="container pt-5 pb-5">
            <stepper :steps="steps">
                <template #item="{item, index}">
                    <div class="row">
                        <div class="col-lg-12">
                            <h5 class="text-left text-primary text-uppercase">{{ item }}</h5>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12" v-if="index == 0">
                            <cart-table/>
                        </div>
                        <div class="col-lg-12" v-if="index == 1">
                            <div class="row">
                                <div class="col-lg-8 h-100">
                                    <small class="text-muted text-uppercase">Tus datos personales</small>
                                    <hr class="m-0">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <label for="nombre_completo" class="col-form-label col-form-label-sm">Nombre completo</label>
                                                <input type="text" name="nombre_completo" class="form-control form-control-sm" :class="{ 'is-invalid': v$.model.nombre_completo.$errors.length > 0 }" placeholder="Nombre completo" required v-model="model.nombre_completo">
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label for="celular" class="col-form-label col-form-label-sm">Celular</label>
                                                <input type="text" name="celular" class="form-control form-control-sm" :class="{ 'is-invalid': v$.model.celular.$error }" placeholder="Celular" required v-model="model.celular">
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label for="email" class="col-form-label col-form-label-sm">Correo electrónico</label>
                                                <input type="text" name="email" class="form-control form-control-sm" :class="{ 'is-invalid': v$.model.email.$error }" placeholder="Correo electronico" required v-model="model.email">
                                            </div>
                                        </div>
                                    </div>
                                    <small class="text-muted text-uppercase">Detalles de facturación</small>
                                    <hr class="m-0">
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label for="razon_social" class="col-form-label col-form-label-sm">Razón social</label>
                                                <input type="text" name="razon_social" class="form-control form-control-sm" :class="{ 'is-invalid': v$.model.razon_social.$error }" placeholder="Razon social" required v-model="model.razon_social">
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label for="nit_ci" class="col-form-label col-form-label-sm">NIT/Carnet de identidad</label>
                                                <input type="number" name="nit_ci" class="form-control form-control-sm" :class="{ 'is-invalid': v$.model.nit_ci.$error }" placeholder="NIT/Carnet de identidad" required v-model="model.nit_ci">
                                            </div>
                                        </div>
                                    </div>
                                    <small class="text-muted text-uppercase">Datos de envío</small>
                                    <hr class="m-0">
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label for="calle" class="col-form-label col-form-label-sm">Dirección de envío</label>
                                                <input type="text" name="calle" class="form-control form-control-sm" :class="{ 'is-invalid': v$.model.direccion.$error }" placeholder="Direccion" required v-model="model.direccion">
                                            </div>
                                            <div class="form-group">
                                                <label for="nro" class="col-form-label col-form-label-sm">Nro de puerta</label>
                                                <input type="text" name="nro" class="form-control form-control-sm" :class="{ 'is-invalid': v$.model.nro_puerta.$error }" placeholder="Nro de puerta" required v-model="model.nro_puerta">
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label for="zona" class="col-form-label col-form-label-sm">Zona</label>
                                                <input type="text" name="zona" class="form-control form-control-sm" :class="{ 'is-invalid': v$.model.zona.$error }" placeholder="Zona" required v-model="model.zona">
                                            </div>
                                            <div class="form-group">
                                                <label for="ciudad" class="col-form-label col-form-label-sm">Ciudad</label>
                                                <select name="ciudad_id" class="form-control form-control-sm" :class="{ 'is-invalid': v$.model.ciudad_id.$error }" v-model="model.ciudad_id">
                                                    <option :value="item.id" v-for="(item, index) in listCiudades" :key="index">{{ item.nombre }}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <label for="ciudad" class="col-form-label col-form-label-sm">Información adicional</label>
                                                <textarea name="descripcion" rows="2" class="form-control form-control-sm" placeholder="Informacion adicional" v-model="model.descripcion"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <label for="ubicacion" class="col-form-label col-form-label-sm">Ubicación</label>
                                                <base-map @position="getCurrentPosition" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <cart-summary title="carrito" :items="shoppingCart" />
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12" v-if="index == 2">
                            <div class="row d-flex justify-content-center">

                                <div class="col-lg-4" v-for="(item, index) in listMetodosPago" :key="index">
                                    <div class="card border-0 h-100" :class="{'active': item.id == model.metodo_pago_id}">
                                        <div class="card-body d-flex align-items-center">
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="metodo_pago_id" :id="'radio' + item.id" :value="item.id" v-model="model.metodo_pago_id">
                                                <label class="form-check-label text-center text-uppercase" :for="'radio' + item.id">{{ item.nombre }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-9 text-center mt-3">
                                    <p><small class="text-muted w-100">{{ currentPayment.descripcion }}</small></p>
                                    <h3 class="text-dark">Compra total: Bs  {{ numberFormat( totalPayment ) }}</h3>
                                    <p><small>El monto total NO incluye costos de envío. Si compraste de muchas tiendas, cada una se pondrá en contacto contigo para gestionar las entregas.</small></p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12" v-if="index == 3">
                            <div class="row d-flex justify-content-center">
                                <div class="col-lg-12">
                                    <detail-order v-if="detailOrder && !isEmptyCart" :order="detailOrder" />
                                    <div class="w-100 mt-4">
                                        <button class="btn btn-primary btn-block" @click="setOrder()" :disabled="sending.checkout">
                                            <div v-if="!sending.checkout">ACEPTAR Y FINALIZAR</div>
                                            <div v-else>
                                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                Realizando pedido...
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </stepper>
        </div>
        <div class="container"></div>
    </div>
</template>

<script>
    import axios from "axios";
    import { mapGetters } from "vuex";
    import useVuelidate from '@vuelidate/core';
    import { required, email, numeric } from '@vuelidate/validators';
    import helpers from "../plugins/helpers";
    import stepper from "../components/Stepper.vue";
    import cartTable from "../components/CartTable.vue";
    import cartSummary from "../components/CartSummary.vue";
    import DetailOrder from "../components/DetailOrder.vue";
    import BaseMap from "../components/BaseMap.vue";

    export default {
        components: {
            stepper,
            cartTable,  
            cartSummary,
            DetailOrder,
            BaseMap,
        },
        setup () {
            return { v$: useVuelidate() }
        },
        data() {
            return {
                sending: {
                    checkout: false
                },
                metodosPago: '',
                ciudades: '',
                latestOrder: '',
                model: {
                    nombre_completo: '',
                    celular: '',
                    email: '',
                    razon_social: '',
                    nit_ci: '',
                    direccion: '',
                    zona: '',
                    nro_puerta: '',
                    ciudad_id: '',
                    descripcion: '',
                    metodo_pago_id: '',
                    ubicacion: '',
                },
                steps: ['Carrito de compras', 'Llena tus datos', 'Metodo de pago', 'Realiza tu pedido'],
            };
        },
        validations () {
            return {
                model: {
                    nombre_completo: { required, $autoDirty: true },
                    celular: { required, numeric, $autoDirty: true },
                    email: { required, email, $autoDirty: true },
                    razon_social: { required, $autoDirty: true },
                    nit_ci: { required, numeric, $autoDirty: true },
                    direccion: { required, $autoDirty: true },
                    zona: { required, $autoDirty: true },
                    nro_puerta: { required, numeric, $autoDirty: true },
                    ciudad_id: { required, $autoDirty: true },
                    metodo_pago_id: { required, $autoDirty: true },
                }
            }
        },
        watch: {
            // whenever question changes, this function will run
            latestOrder(newValue) {
                if ( newValue ) {
                    this.loadLatestOrder();
                }
            }
        },
        computed: {
			...mapGetters({
				shoppingCart: "shoppingCart",
				shoppingCartSend: "shoppingCartSend",
				totalProducts: "totalProducts",
				totalPayment: "totalPayment",
                isEmptyCart: "isEmptyCart",
                user: "auth/user",
                authenticated: "auth/authenticated"
            }),
            listMetodosPago() {
                return this.metodosPago.data;
            },
            listCiudades() {
                return this.ciudades.data;
            },
            currentPayment() {
                return !this.model.metodo_pago_id ? '' : this.listMetodosPago.find(item => item.id == this.model.metodo_pago_id);
            },
            currentCity() {
                return !this.model.ciudad_id ? '' : this.listCiudades.find(item => item.id == this.model.ciudad_id);
            },
            detailOrder() {
                let order = this.model;
                order.items = this.shoppingCart;
                order.metodo_pago = this.currentPayment;
                order.ciudad = this.currentCity;
                return order;
            }
		},
        methods: {
            getMetodosPago(params) {
                return axios.get(process.env.VUE_APP_GOU_AFILIADO_URL + "api/v2/payment-methods", {
                    params
                });
            },
            getCiudades(params) {
                return axios.get(process.env.VUE_APP_GOU_AFILIADO_URL + "api/ciudades", {
                    params
                })
            },
            getLatestOrder() {
                let vm = this;
                if( ! vm.authenticated ) {
                    return;
                }
                axios.get(process.env.VUE_APP_GOU_AFILIADO_URL + "api/v2/latest-order", {
                    params: {
                        user: vm.user.id_usuario
                    }
                }).then((response) => {
                    console.log('getLatestOrder success', response)
                    vm.latestOrder = response.data;
                }).catch((error) => {
                    console.error('getLatestOrder error', error.response);
                })
            },
            getCurrentPosition(position) {
                console.log('getCurrentPosition', position)
                this.model.ubicacion = JSON.stringify(position);
            },
            numberFormat (value) {
                return helpers.numberFormat(value, 2);
            },
            loadLatestOrder() {
                this.model.celular = this.model.celular || this.latestOrder.data.celular;
                this.model.razon_social = this.latestOrder.data.razon_social;
                this.model.nit_ci = this.latestOrder.data.nit_ci;
                this.model.direccion = this.latestOrder.data.direccion;
                this.model.zona = this.latestOrder.data.zona;
                this.model.nro_puerta = this.latestOrder.data.nro_puerta;
                this.model.descripcion = this.latestOrder.data.descripcion;
                this.model.ciudad_id = this.latestOrder.data.ciudad.id;
                this.model.metodo_pago_id = this.latestOrder.data.metodo_pago.id;
            },
            async setOrder() {
				const vm = this;
                if( vm.isEmptyCart ) {
                    vm.$notify({
                        group: "app",
                        type: "warn",
                        text: "Agrega al menos un producto al carrito",
                    });
                    return;
                }
                
                if(!vm.authenticated) {
                    vm.$notify({
                        group: "app",
                        type: "warn",
                        text: "Inicia sesion para continuar"
                    })
                    return;
                }

                const result = await this.v$.$validate()
                if (!result) {
                    helpers.showErrorsformValidation(this.v$.$errors, this.$notify);
                    return;
                }

                vm.sending.checkout = true;
                let form = vm.model;
                form.usuario_id = vm.user.id_usuario;
                form.total = vm.totalPayment;
                form.items = vm.shoppingCartSend;

				axios.post(process.env.VUE_APP_GOU_AFILIADO_URL + "api/v2/pedidos", form)
				.then(function (response) {
                    console.log(response);
					vm.$notify({
						group: "app",
						type: "success",
						text: "Gracias por comprar en Gou Tienda!",
					});
                    
                    vm.sending.checkout = false;
                    vm.$router.push({
                        name: 'pedido',
                        params: { pedidoId: response.data.data.id },
                    })
                    vm.$store.dispatch("emptyCart");
				})
				.catch(function (error) {
					console.error(error.response);
                    vm.sending.checkout = false;
                    vm.$notify({
                        group: "app",
                        type: "error",
                        text: "El proceso no pudo completarse"
                    })
				});
			},
        },
        mounted () {
            this.model.nombre_completo = this.user.nombre_completo;
            this.model.celular = this.user.celular;
            this.model.email = this.user.email;
        },
        created () {
            let vm = this;
            Promise.all([
                vm.getMetodosPago({estado: 'activo'}),
                vm.getCiudades({estado: 'activo'})
            ])
            .then(function (results) {
                const metodosPagoResponse = results[0];
                const ciudadesResponse = results[1];
                vm.metodosPago = metodosPagoResponse.data;
                vm.ciudades = ciudadesResponse.data;
            })
            .catch(function (error) {
                console.error('get metodosPago-ciudades error', error.response);
            });
            vm.getLatestOrder();
        }
    };
</script>

<style scoped>
    /* radio button card */
    .card {
        align-items: center;
        background-color: #F8F8F8;
    }
    .card.active {
        background-color: var(--color-light-primary);
        color: #FFF;
    }

    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
        position: absolute;
        left: -9999px;
    }

    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label {
        cursor: pointer;
    }
    /* end */
</style>
