<template>
    <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
            <div class="card shadow-lg border-0">
                <div class="card-header bg-transparent pb-5">
                    <h3 class="card-title text-center">Registrarme</h3>
                    <base-social-login />
                </div>
                <div class="card-body px-lg-5 py-lg-5">
                    <div class="text-center text-muted mb-4">
                        <small>Datos de registro</small>
                    </div>
                    <form role="form" @submit.prevent="submit">
                        <base-input
                            formClasses="input-group-alternative mb-3"
                            placeholder="Ingresa tu nombre completo"
                            addon-left-icon="mdi mdi-18px mdi-account-outline"
                            :valid="v$.model.nombre_completo.$errors.length > 0 ? false : null"
                            v-model="model.nombre_completo"
                        >
                        </base-input>
                        <base-input
                            formClasses="input-group-alternative mb-3"
                            placeholder="Ingresa tu email"
                            addon-left-icon="mdi mdi-18px mdi-email-outline"
                            :valid="v$.model.email.$errors.length > 0 ? false : null"
                            v-model="model.email"
                        >
                        </base-input>
                        <base-input
                            formClasses="input-group-alternative mb-3"
                            placeholder="Ingresa tu celular"
                            addon-left-icon="mdi mdi-18px mdi-cellphone"
                            :valid="v$.model.celular.$errors.length > 0 ? false : null"
                            v-model="model.celular"
                        >
                        </base-input>
                        <base-input
                            formClasses="input-group-alternative mb-3"
                            placeholder="Ingresa tu contraseña"
                            type="password"
                            addon-left-icon="mdi mdi-18px mdi-lock-outline"
                            :valid="v$.model.password.$errors.length > 0 ? false : null"
                            v-model="model.password"
                        >
                        </base-input>
                        <base-input
                            formClasses="input-group-alternative mb-3"
                            placeholder="Reingresa tu contraseña"
                            type="password"
                            addon-left-icon="mdi mdi-18px mdi-lock-outline"
                            :valid="v$.model.password_confirmation.$errors.length > 0 ? false : null"
                            v-model="model.password_confirmation"
                        >
                        </base-input>

                        <base-checkbox class="custom-control-alternative">
                            <span class="text-muted">Acepto los terminos de servicio</span>
                        </base-checkbox>
                        <div class="text-center">
                            <button type="submit" class="btn btn-primary my-4 btn-block" :disabled="sending.register">
                                <div v-if="!sending.register">REGISTRARME</div>
                                <div v-else>
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Registrandome...
                                </div>
                            </button>
                        </div>
                        <div class="text-center">
                            <small>Ya tienes una cuenta?
                                <router-link class="text-primary" to="/login">
                                    Inicia sesion!
                                </router-link>
                            </small>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapActions } from "vuex";
    import useVuelidate from '@vuelidate/core';
    import { required, email, numeric, minLength, maxLength, sameAs } from '@vuelidate/validators';
    import helpers from "../plugins/helpers";
    import BaseSocialLogin from "../components/BaseSocialLogin.vue";
    export default {
        name: "register",
        setup () {
            return { v$: useVuelidate() }
        },
        components: {
            BaseSocialLogin,
        },
        data() {
            return {
                sending: {
                    register: false
                },
                model: {
                    nombre_completo: "",
                    email: "",
                    celular: "",
                    password: "",
                    password_confirmation: ""
                },
            };
        },
        validations () {
            return {
                model: {
                    nombre_completo: {
                        required,
                        maxLength: maxLength(50)
                    },
                    email: { 
                        required,
                        email,
                        $autoDirty: true
                    },
                    celular: {
                        required,
                        numeric,
                        minLength: minLength(8),
                        maxLength: maxLength(8),
                        $autoDirty: true
                    },
                    password: {
                        required,
                        minLength: minLength(6),
                        $autoDirty: true
                    },
                    password_confirmation: {
                        required,
                        sameAsPassword: sameAs(this.model.password),
                        $autoDirty: true
                    }
                }
            }
        },
        methods: {
            ...mapActions({
                register: "auth/register"
            }),
            async submit() {
                let vm = this;
                const result = await vm.v$.$validate()
                if (!result) {
                    helpers.showErrorsformValidation(vm.v$.$errors, vm.$notify);
                    return;
                }
                
                this.sending.register = true;
                this.register(this.model).then((response) => {
                    console.log('register success', response);
                    this.$router.replace({
                        name: 'dashboard'
                    })
                    this.sending.register = false;
                }).catch((error) => {
                    console.log('submit register error', error.response);
                    this.sending.register = false;
                });
            }
        },
    };
</script>
<style></style>
